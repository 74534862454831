import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import CustomInput from "../../../../components/v2/common/custom-input/custom-input";
import TextComponent from "../../../../components/v2/common/text-component/text-component";
import { FilesList } from "../files-list/files-list";
import { FileActionsProps } from "./file-action";
import styles from './file-action.module.scss';
import { FileOrder } from "../../../../store/slices/files-order.slice";
import { useEffect, useState } from "react";
import { FileType } from "../../../../store/models/folder-type.enum";
import { MoveItemFolderDto } from "../../../../store/models/folders.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch, RootState } from "../../../../store/store";
import svgPaneDeleteWhite from "../../../../assets/v2/close-white.svg";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { fetchRootElementsThunk, moveItemThunk } from "../../../../store/thunks/files-order.thunk";
import { MoveItemFormDto } from "../../../../store/models/form.interface";
import { getPathFromRootForCurrentItem } from "./file-actions-utils";

export function MoveFolder(props: Readonly<FileActionsProps>) {
  const folders = useSelector((state: RootState) => state.folders.data);
  const forms = useSelector((state: RootState) => state.forms.data);
  const [targetPath, setTargetPath] = useState('');
  const [oldPath, setOldPath] = useState('');
  const [currentItemName, setCurrentItemName] = useState('');
  const [targetFile, setTargetFile] = useState<FileOrder>();
  const [targetIndexArr, setTargetIndexArr] = useState<number[]>([0]);
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();

  useEffect(() => {
    if (!props.selectedFile) {
      return;
    }
    if (oldPath === '') {
      const currentPath = getPathFromRootForCurrentItem(props.selectedFile, folders, forms);
      setOldPath('/' + currentPath.join('/'));
      setTargetPath('/' + currentPath.join('/'));
      setCurrentItemName(currentPath[currentPath.length - 1]);
    }
  }, []);

  const generateNewPath = (file: FileOrder) => {
    const newPathBase = getPathFromRootForCurrentItem(file, folders, forms);
    newPathBase.push(currentItemName);
    setTargetPath('/' + newPathBase.join('/'));
  }

  const moveItem = async () => {
    try {
      if (props.selectedFile) {
        const data = props.selectedFile;
        let payload: MoveItemFolderDto | MoveItemFormDto;
        if (data.type === FileType.FOLDER) {
          payload = {
            id: data.id,
            type: FileType.FOLDER,
            data: {
              parentID: targetFile ? targetFile.id : null
            },
            indexArr: props.indexArr,
            targetIndexArr: targetIndexArr
          }
        } else {
          payload = {
            id: data.id,
            type: FileType.FORM,
            data: {
              folderID: targetFile ? targetFile.id : null
            },
            indexArr: props.indexArr,
            targetIndexArr: targetIndexArr
          }
        }
        await dispatch(moveItemThunk(payload)).unwrap();
      }
      dispatch(fetchRootElementsThunk());
      props.setShowModal(false);
      setTargetPath('/');
      props.setSelectedFile(undefined);
      props.setIndexArr([]);
      toast.success(t("move_item_success"));
      props.setResetFileState((prev) => !prev);
    } catch (err) {
      console.log("err", err);
      toast.error(t("move_item_error"));
    }
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.pathContainer}`}>
        <TextComponent textType={'Content'}>{t("old_path")}</TextComponent>
        <CustomInput inputStyleType={"fade"} inputType={'text'} value={`${oldPath}`} disabled onChange={(e) => { }} />
      </div>
      <div className={styles.panel}>
        <div className={styles.pathTitle}>
          <TextComponent textType={'Content'}>{t("select_path")}</TextComponent>
          <div className={styles.info}>{t("optional")}</div>
          {
            targetFile &&
            <div>
              <CustomButton
                style={{
                  background: "var(--secondary-color)",
                  padding: "0.2rem",
                  borderRadius: '50%',
                  border: 'none',
                  boxSizing: "border-box",
                  display: 'flex',
                  width: '1rem',
                  height: '1rem'
                }}
                buttonType='button'
                classType='tertiary'
                onClick={() => { props.setSelectedFile(undefined); setTargetPath('/') }}>
                <img style={{ width: '0.75rem' }} src={svgPaneDeleteWhite} alt="delete" />
              </CustomButton>
            </div>
          }
        </div>
        {
          !targetFile &&
          <div className={styles.info}>{t("base_path_info")}</div>
        }
        <div className={styles.contentPane}>
          <FilesList actionMode currentFile={props.selectedFile} selectedFile={targetFile} onSelect={(file, indexArr) => { setTargetFile(file); setTargetIndexArr(indexArr); generateNewPath(file) }} showMenu={false} showFiles={false} showFolders={true} />
        </div>
      </div>
      <div className={`${styles.pathContainer}`}>
        <TextComponent textType={'Content'}>{t("new_path")}</TextComponent>
        <CustomInput inputStyleType={"fade"} inputType={'text'} value={`${targetPath}`} disabled onChange={(e) => { }} />
      </div>
      <div className={`${styles.panel} ${styles.btnContainer}`} >
        <CustomButton buttonType="button" classType="secondary" onClick={() => { setTargetPath('/'); setTargetFile(undefined); }}>{t("clear")}</CustomButton>
        <CustomButton buttonType="button" classType="quaternary" onClick={moveItem}>{t("move")}</CustomButton>
      </div>
    </div>
  )
}
