import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import styles from './file-item.module.scss';
import FolderImg from '../../../../../assets/folder.svg';
import CloseTriangleImg from '../../../../../assets/close-triangle.svg';
import OpenTriangleImg from '../../../../../assets/open-triangle.svg';
import FormImg from '../../../../../assets/form.svg';
import { FileOrder } from "../../../../../store/slices/files-order.slice";
import { GetFolderItemRespDto } from "../../../../../store/models/folders.interface";
import { FileType } from "../../../../../store/models/folder-type.enum";
import { RootDispatch } from "../../../../../store/store";
import { useDispatch } from "react-redux";
import { fetchFoldersAndFormsByIdThunk } from "../../../../../store/thunks/files-order.thunk";
import menuDotsIcon from './menu-three-dots.svg';
import { FileActionEnum } from "../../file-action/file-action";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../../store/models/form.interface";
import toast from "react-hot-toast";

export interface FileItemProps {
  file: FileOrder;
  formLookup: Record<string, Form>;
  folderLookup: Record<string, GetFolderItemRespDto>;
  depthCount: number;
  actionMode?: boolean;
  onSelect?: (file: FileOrder, indexArr: number[]) => void;
  selectedFile?: FileOrder;
  setFileAction?: Dispatch<SetStateAction<FileActionEnum | undefined>>;
  setShowFileActionsModal?: Dispatch<SetStateAction<boolean>>;
  showMenu: boolean;
  indexArr: number[];
  showChildrenFolders: boolean;
  showChildrenFiles: boolean;
  resetFileState?: boolean;
  currentFile?: FileOrder;
}

export function FileItem(props: Readonly<FileItemProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const [hideIcon, setHideIcon] = useState(false);
  const dispatch: RootDispatch = useDispatch();
  const handleClickRef = useRef<HTMLDivElement>(null);
  const handleMenuRef = useRef<HTMLImageElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setIsOpen(false);
    setHideIcon(false);
  }, [props.resetFileState]);

  useEffect(() => {
    const onClickExpand = async (id: string) => {
      try {
        const data = await dispatch(fetchFoldersAndFormsByIdThunk(id)).unwrap();
        if (data.children?.files && data.children.files.length === 0) {
          setHideIcon(true);
        }
      } catch (e) {
        toast.error(t('file_children_fetch_err'));
        setHideIcon(false);
        setIsOpen(false);
      }
    }

    if (isOpen) onClickExpand(props.file.id);
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (handleClickRef.current && handleMenuRef.current) {
        if (handleMenuRef.current.contains(event.target as Node)) {
          if (handleClickRef.current.style.display === 'none') {
            handleClickRef.current.style.display = 'flex';
            event.stopPropagation();
          } else {
            handleClickRef.current.style.display = 'none';
          }
        }
        else if (!handleClickRef.current.contains(event.target as Node)) {
          handleClickRef.current.style.display = 'none';
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onClickAction = (type: FileActionEnum) => {
    if (props.setFileAction && props.setShowFileActionsModal && props.onSelect && props.indexArr) {
      props.onSelect(props.file, props.indexArr);
      props.setFileAction(type);
      props.setShowFileActionsModal(true);
    }
  }

  return (<>
    <div
      className={`${styles.listItem} ${hideIcon && props.file.type === FileType.FOLDER && !props.actionMode ? styles.noInteraction : ''} ${props.actionMode && props.selectedFile?.id === props.file.id ? styles.selectedItem : ''} ${props.actionMode && props.currentFile?.id === props.file.id ? styles.disabled : ''}`}
      style={{ paddingLeft: props.depthCount === 0 ? '0.75rem' : `calc(${props.depthCount} * 2.25rem)` }}
      onClick={() => {
        if (props.actionMode && props.onSelect) {
          props.onSelect(props.file, props.indexArr);
        }
        if (props.file.type === FileType.FOLDER && !hideIcon) {
          setIsOpen(prev => !prev);
        }
        if (props.file.type === FileType.FORM && !props.actionMode) {
          navigate(props.file.id);
        }
      }}
    >
      {
        isOpen
          ? <img src={OpenTriangleImg} alt="open-triangle" className={`${styles.triangleIcon} ${props.file.type === FileType.FORM || hideIcon ? styles.hideIcon : ''}`} />
          : <img src={CloseTriangleImg} alt="close-triangle" className={`${styles.triangleIcon} ${props.file.type === FileType.FORM || hideIcon ? styles.hideIcon : ''}`} />
      }
      {
        props.file.type === FileType.FOLDER
          ? <>
            <img src={FolderImg} alt="folder" className={styles.icon} />
            <div>{props.folderLookup[props.file.id].displayName}<span style={{ color: "blue", padding: "0 0.5rem", fontSize: "10px" }}>[ID:{props.folderLookup[props.file.id].name}]</span></div>
          </>
          : <><img src={FormImg} alt="form" className={styles.icon} />
            <div>{props.formLookup[props.file.id].name}</div></>
      }
      {
        props.showMenu &&
        <div ref={handleMenuRef} className={styles.menuContainer}>
          <img alt="menu-dots" src={menuDotsIcon} className={`${styles.icon} ${styles.menuIcon}`} />
          {
            <div style={{ display: 'none' }} className={styles.activeSettings} ref={handleClickRef}>
              <div className={styles.settingsMenu}>
                {
                  <button className={styles.menuItem} onClick={(e) => { e.stopPropagation(); onClickAction(FileActionEnum.MOVE) }}>{t("move")}</button>
                }
                {
                  props.file.type === FileType.FOLDER &&
                  <>
                    <div className={styles.line}></div>
                    <button className={styles.menuItem} onClick={(e) => { e.stopPropagation(); onClickAction(FileActionEnum.RENAME) }}>{t("rename")}</button>
                  </>
                }
                <div className={styles.line}></div>
                <button className={styles.menuItem} onClick={(e) => { e.stopPropagation(); onClickAction(FileActionEnum.SHARE) }}>{t("share")}</button>
                {
                  <>
                    <div className={styles.line}></div>
                    <button className={styles.menuItem} onClick={(e) => { e.stopPropagation(); onClickAction(FileActionEnum.DELETE) }}>{t("delete")}</button>
                  </>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
    {
      isOpen &&
      props.file.type === FileType.FOLDER &&
      props.file.children.map((childFile, index) => {
        if ((((childFile.type === FileType.FOLDER) && props.showChildrenFolders) || ((childFile.type === FileType.FORM) && props.showChildrenFiles))) {
          return (
            <FileItem {...props} file={childFile} depthCount={props.depthCount + 1} key={childFile.id} actionMode={props.actionMode} onSelect={props.onSelect} selectedFile={props.selectedFile} indexArr={[...props.indexArr, index]} resetFileState={props.resetFileState} />
          )
        }
        return <></>
      })
    }
  </>
  )
}
