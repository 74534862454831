import styles from './dashboard.module.scss';
import { TFunction } from 'i18next';
import TextComponent from '../../../components/v2/common/text-component/text-component';

export default function DashboardCards(props: Readonly<DashboardCardsProps>) {

  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <div className={styles.cardText}>
          <TextComponent textType='Content'>
            <div className={styles.cardFont}>{`${props.t("published")}`}</div>
          </TextComponent>
        </div>
        <div>
          <TextComponent textType='Header'>
            <div>{props.publishedFormsCount}</div>
          </TextComponent>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.cardText}>
          <TextComponent textType='Content'>
            <div className={styles.cardFont} >{`${props.t("forms_drafts_dashboard")}`}</div>
          </TextComponent>
        </div>
        <div>
          <TextComponent textType='Header'>
            <div>{props.nonPublishedFormsCount}</div>
          </TextComponent>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.cardText}>
          <TextComponent textType='Content'>
            <div className={styles.cardFont} >{`${props.t("forms_waiting_for_approval")}`}</div>
          </TextComponent>
        </div>
        <div>
          <TextComponent textType='Header'>
            <div>{props.waitingForPublishFormsCount}</div>
          </TextComponent>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.cardText}>
          <TextComponent textType='Content'>
            <div className={styles.cardFont}>{`${props.t("average_response_rate_dashboard")}`}</div>
          </TextComponent>
        </div>
        <div>
          <TextComponent textType='Header'>
            <div>{props.avgResponseRate < 100 ? props.avgResponseRate.toFixed(2) + "%" : props.avgResponseRate + "%"}</div>
          </TextComponent>
        </div>
      </div>
    </div>
  )
}

interface DashboardCardsProps {
  t: TFunction<"translation", undefined>;
  publishedFormsCount: number;
  nonPublishedFormsCount: number;
  avgResponseRate: number;
  waitingForPublishFormsCount: number;
}
