import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import TextComponent from "../../../../components/v2/common/text-component/text-component";
import { FilesList } from "../files-list/files-list";
import { FileActionsProps } from "./file-action";
import svgPaneDeleteWhite from "../../../../assets/v2/close-white.svg";
import styles from './file-action.module.scss';
import CustomInput from "../../../../components/v2/common/custom-input/custom-input";
import { useState } from "react";
import { FileOrder } from "../../../../store/slices/files-order.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch, RootState } from "../../../../store/store";
import { FileType } from "../../../../store/models/folder-type.enum";
import { CreateFolderReqDto } from "../../../../store/models/folders.interface";
import { createFolderThunk } from "../../../../store/thunks/folders.thunk";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { fetchRootElementsThunk } from "../../../../store/thunks/files-order.thunk";
import { getPathFromRootForCurrentItem } from "./file-actions-utils";

export function CreateFolder(props: Readonly<FileActionsProps>) {
  const folders = useSelector((state: RootState) => state.folders.data);
  const fileOrder = useSelector((state: RootState) => state.filesOrder.order);
  const [selectedFile, setSelectedFile] = useState<FileOrder>();
  const [targetPath, setTargetPath] = useState('/');
  const [folderToBeCreatedName, setFolderToBeCreatedName] = useState('');
  const dispatch: RootDispatch = useDispatch();
  const { t } = useTranslation();

  const generateNewPath = (file: FileOrder) => {
    const newPathBase = getPathFromRootForCurrentItem(file, folders);
    setTargetPath('/' + newPathBase.join('/'));
  }

  const createFolder = async () => {
    const payload: CreateFolderReqDto = {
      displayName: folderToBeCreatedName,
      parentID: selectedFile && selectedFile.type === FileType.FOLDER ? selectedFile.id : undefined,
    }
    try {
      const folder = await dispatch(createFolderThunk(payload)).unwrap();
      if (folder) {
        props.setShowModal(false);
        toast.success(t("create_folder_success"));
        dispatch(fetchRootElementsThunk());
        setTargetPath('/');
        setFolderToBeCreatedName('');
        setSelectedFile(undefined);
        props.setResetFileState((prev) => !prev);
      }
    } catch (err) {
      toast.error(t("create_folder_err"));
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <TextComponent textType={'Content'}>{t("folder_name")}</TextComponent>
        <CustomInput inputStyleType={"primary"} inputType={'text'} value={folderToBeCreatedName} onChange={(e) => { setFolderToBeCreatedName(e.target.value) }} />
      </div>
      {
        fileOrder.length > 0 &&
        <>
          <div className={styles.panel}>
            <div className={styles.pathTitle}>
              <TextComponent textType={'Content'}>{t("select_path")}</TextComponent>
              <div className={styles.info}>{t("optional")}</div>
              {
                selectedFile &&
                <div>
                  <CustomButton
                    style={{
                      background: "var(--secondary-color)",
                      padding: "0.2rem",
                      borderRadius: '50%',
                      border: 'none',
                      boxSizing: "border-box",
                      display: 'flex',
                      width: '1rem',
                      height: '1rem'
                    }}
                    buttonType='button'
                    classType='tertiary'
                    onClick={() => { setSelectedFile(undefined); setTargetPath('/') }}>
                    <img style={{ width: '0.75rem' }} src={svgPaneDeleteWhite} alt="" />
                  </CustomButton>
                </div>
              }
            </div>
            {
              !selectedFile &&
              <div className={styles.info}>{t("base_path_info")}</div>
            }
            <div className={styles.contentPane}>
              <FilesList actionMode selectedFile={selectedFile} onSelect={(file) => { setSelectedFile(file); generateNewPath(file) }} showMenu={false} showFolders={true} showFiles={false} />
            </div>
          </div>
          <div className={`${styles.pathContainer}`}>
            <TextComponent textType={'Content'}>{t("path")}</TextComponent>
            <CustomInput inputStyleType={"fade"} inputType={'text'} value={`${targetPath}${targetPath === '/' ? '' : '/'}${folderToBeCreatedName}`} disabled onChange={(e) => { }} />
          </div>
        </>
      }
      <div className={`${styles.panel} ${styles.btnContainer}`} >
        <CustomButton buttonType="button" classType="secondary" onClick={() => { setTargetPath('/'); setFolderToBeCreatedName(''); setSelectedFile(undefined); }}>{t("clear")}</CustomButton>
        <CustomButton buttonType="button" classType="quaternary" onClick={() => { createFolder() }}>{t("create")}</CustomButton>
      </div>
    </div>
  )
}
