import { UserMetadataKey } from "../../store/models/user-metadata-key.enum";
import store from "../../store/store";

export default function hasSufficientScopes(requiredRoles: string[]): boolean {
  const state = store.getState();
  const activeOrg = state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION];
  let permissions: string[] = [];

  //check for relevant permissions
  for (const o of state.user.profileInfo.organizations) {
    if (o.name === activeOrg) {
      permissions = permissions.concat(...o.permissions);
    }
  }
  const res = requiredRoles.some(irp => {
    return permissions.includes(irp);
  });
  return res;
}

export function hasActiveSubscription() {
  const state = store.getState();
  const isActiveSubscription = state.subscription.data.isActive;
  return isActiveSubscription;
}

export function hasActiveOrg(): boolean {
  const state = store.getState();
  return state.user.profileInfo.organizations.length > 0
}
