import axios from "axios";
import { GenerateApiUrl } from "../../api/generate-api-url";
import store from "../../store/store";

export async function hasOpenFGARelation(relationObj: string, objectType: string, objectId: string): Promise<boolean> {
  const state = store.getState();
  const loggedInUserId = state.user.profileInfo.userId;

  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `access/check?userId=${loggedInUserId}&relationObj=${relationObj}&objectType=${objectType}&targetObjectId=${objectId}`, true);
  const response = await axios.get(apiUrl);
  return response.data;
}
