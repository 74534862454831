import { useEffect, useRef, useState } from 'react';
import styles from './side-bar.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import HamburgerMenu from '../hamburger-menu/hamburger-menu';
import { SideBarProps } from './side-bar.props';
import Modal from '../modal/modal';
import CustomSelect from '../custom-select/custom-select';
import CustomButton from '../custom-button/custom-button';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../store/store';
import { DropdownDataItem } from '../custom-select/custom-select.props';
import { MultiValue, SingleValue } from 'react-select';
import toast from 'react-hot-toast';
import { setUserMetadataByKey } from '../../../../api/profile';
import { SetUserMetadataDto } from '../../../../store/models/profile.interface';
import { UserMetadataKey } from '../../../../store/models/user-metadata-key.enum';
import { Accordion } from '../accordion/accordion';
import ProfileMenu from './profile-menu/profile-menu';
import AddOrganizationModal from '../../../../pages/v2/setting/add-organization/add-organization-modal';
import pngLogo from "../../../../assets/v2/logo.png";
import svgAddOrg from "../../../../assets/v2/icons/add-org.svg";
import svgDashboard from "../../../../assets/v2/icons/dashboard.svg";
import svgForms from "../../../../assets/v2/icons/forms.svg";
import svgFeedback from "../../../../assets/v2/icons/feedback.svg";
import svgUserGroup from "../../../../assets/v2/icons/user-group-outlined.svg";
import svgUserGroupFilled from "../../../../assets/v2/icons/user-group-filled.svg";
import svgUserGroupMobile from "../../../../assets/v2/icons/user-group-filled-white.svg";
import svgInvitation from "../../../../assets/v2/icons/invitation.svg";
import svgPaneDelete from "../../../../assets/v2/close.svg";
import svgProfileIcon from "../../../../assets/v2/icons/profile-icon.svg";
import svgProfile from "../../../../assets/REPLACE-THESE/profile.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgSettingsV2 from "../../../../assets/v2/icons/settings-v2.svg";
import svgSwitchOrg from "../../../../assets/v2/icons/switch-org.svg";
import ToolTip from '../tool-tip/tool-tip';
import svgDashboardOutlined from '../../../../assets/v2/dashboard-outlined.svg';
import svgDashboardFilled from '../../../../assets/v2/dashboard-filled.svg';
import svgFormOutline from '../../../../assets/v2/forms-outlined.svg';
import svgFormFilled from '../../../../assets/v2/forms-filled.svg';
import svgSettingOutlined from '../../../../assets/v2/settings-outlined.svg';
import svgSettingFilled from '../../../../assets/v2/settings-filled.svg';
import svgFeedBackOutlined from '../../../../assets/v2/feedback-outlined.svg';
import svgFeedBackFilled from '../../../../assets/v2/feedback-filled.svg'
import { useTranslation } from 'react-i18next';
import { fetchSubscriptionThunk } from '../../../../store/thunks/subscription.thunk';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';

export default function SideBar(props: SideBarProps) {
  const dispatch: RootDispatch = useDispatch();
  const { t } = useTranslation();
  const [activePageButton, setActivePageButton] = useState<string>("");
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [toggleSettings, setToggleSettings] = useState(false);
  const [userGroupMenu, setUserGroupMenu] = useState(false);
  const [toggleSwitchOrg, setToggleSwitchOrg] = useState(false);
  const [toggleAddOrg, setToggleAddOrg] = useState(false);
  const profile = useSelector((state: RootState) => state.user.profileInfo);
  const [activeOrg, setActiveOrg] = useState<MultiValue<DropdownDataItem> | SingleValue<DropdownDataItem>>();
  const [isModified, setIsModified] = useState<Boolean>(false);
  const [isAccordianOpen, setIsAccordianOpen] = useState(false);
  const [userAccordian, setUserAccordian] = useState(false);
  const [isProfileMenuActive, setIsProfileMenuActive] = useState(false);
  const handleClick = useRef<HTMLDivElement>(null);
  const [activeOrgName, setActiveOrgName] = useState(profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);
  const location = useLocation();
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);

  useEffect(() => {
    activePageChecker();
  }, [window.location.pathname]);

  useEffect(() => {
    setActiveOrgName(profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);
  }, [profile])
  const activePageChecker = () => {
    const pageArray = window.location.pathname.split("/");
    if (pageArray.includes("dashboard")) {
      setActivePageButton("dashboard");
      return
    }
    if (pageArray.includes("forms")) {
      setActivePageButton("forms");
      return
    }
    if (pageArray.includes("responses")) {
      setActivePageButton("forms");
      return
    }
    if (pageArray.includes("account")) {
      setActivePageButton("account");
      return;
    }
    if (pageArray.includes("settings")) {
      setActivePageButton("settings");
      return;
    }
    if (pageArray.includes("feedback")) {
      setActivePageButton("feedback");
      return;
    }
    if (pageArray.includes("subscription-plans")) {
      setActivePageButton("settings");
      return;
    }
  }

  useEffect(() => {
    dispatch(fetchSubscriptionThunk());
    const activeOrg = profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION];
    const displayName = profile.organizations.filter((i) => i.name === activeOrg);
    if (displayName && displayName.length > 0 && displayName[0].displayName) {
      setActiveOrg({
        label: `${displayName[0].displayName} (${activeOrg})`,
        value: activeOrg
      });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (handleClick.current && !handleClick.current.contains(event.target)) {
        setToggleSettings(false);
        setUserGroupMenu(false);
        activePageChecker();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [])


  useEffect(() => {
    if (activeOrg && (activeOrg as DropdownDataItem).value === profile.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]) {
      setIsModified(false);
    } else {
      setIsModified(true);
    }
  }, [activeOrg]);

  const logoutAction = () => {
    keycloak.logout({
      redirectUri: window.location.origin
    });
  }

  async function switchOrg() {
    try {
      const reqData: SetUserMetadataDto = {
        value: (activeOrg as DropdownDataItem).value
      };
      const res = await setUserMetadataByKey(UserMetadataKey.ACTIVE_ORGANIZATION, reqData);
      if (res.status === 201) {
        navigate(`o/${reqData.value}/dashboard`);
        window.location.reload();
      }
    } catch (err) {
      toast.error(`${err}`);
    }
  }

  function checkWorkspace() {
    if (!activeOrgName) {
      toast.error('create workspace to access the application');
      return false;
    }
    return true;
  }

  function checkPermission(permission: string[]): boolean {
    return isLatestSubscriptionActive && hasSufficientScopes(permission);
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={`${styles.profileContainer} `}>
          <img
            onClick={() => { setActivePageButton("dashboard"); navigate(`/o/${activeOrgName}/dashboard`) }}
            src={pngLogo} alt={"smart-forms"}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className={styles.cubeContainer}>
          <div className={styles.menuContainer}>
            <ToolTip position='top' content={t("nav_bar_dashboard")}>
              <button className={styles.menuItems} onClick={() => { if (checkWorkspace()) { navigate(`o/${activeOrgName}/dashboard`); setActivePageButton('dashboard'); } }}>
                <img className={styles.menuIcons} src={location.pathname.includes('dashboard') ? svgDashboardFilled : svgDashboardOutlined} alt="" />
              </button>
            </ToolTip>
            <ToolTip position='top' content={t("nav_bar_forms")}>
              <button className={styles.menuItems} onClick={() => { if (checkWorkspace()) { navigate(`o/${activeOrgName}/forms`); setActivePageButton('forms'); } }}>
                <img className={styles.menuIcons} src={location.pathname.includes('forms') ? svgFormFilled : svgFormOutline} alt="" />
              </button>
            </ToolTip>
            <ToolTip position='top' content={t("settings")}>
              <div className={`${toggleSettings ? styles.activeSettings : ''}`}>
                <button className={`${styles.menuItems} ${activePageButton === "settings" && styles.activeMenuItems}`}
                  style={{ margin: '0 auto' }}
                  onClick={() => { setActivePageButton("settings"); setToggleSettings(!toggleSettings); }}>
                  <img style={{ width: "1.6rem" }} className={styles.menuIcons} src={(activePageButton === "settings") ? svgSettingFilled : svgSettingOutlined} alt="" />
                </button>
                <div className={styles.settingsMenu} ref={handleClick}>
                  <div onClick={() => { setToggleSettings(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/settings`); }}>{t("workspace_settings")}</div>
                  {
                    checkPermission(['storage:read']) &&
                    <>
                      <div className={styles.line}></div>
                      <div onClick={() => { setToggleSettings(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/storage-manager`); }}>{t("storage_management")}</div>
                    </>
                  }
                  {
                    checkPermission(['integrations:read']) &&
                    <>
                      <div className={styles.line}></div>
                      <div onClick={() => { setToggleSettings(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/integrations`); }}>{t("integrations")}</div>
                    </>
                  }
                  {
                    checkPermission(['apikeys:read']) &&
                    <>
                      <div className={styles.line}></div>
                      <div onClick={() => { setToggleSettings(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/api-keys`); }}>{t("api_key_management")}</div>
                    </>
                  }
                </div>
              </div>
            </ToolTip>
            <ToolTip position='top' content={t("nav_bar_users")}>
              <div className={`${userGroupMenu ? styles.activeSettings : ''}`}>
                <button className={`${styles.menuItems} ${activePageButton === "user-settings" && styles.activeMenuItems}`}
                  style={{ margin: '0 auto' }}
                  onClick={() => { setActivePageButton("user-settings"); setUserGroupMenu(!userGroupMenu); }}>
                  <img style={{ width: "1.6rem" }} className={styles.menuIcons} src={(activePageButton === "user-settings") ? svgUserGroupFilled : svgUserGroup} alt="" />
                </button>
                <div className={styles.settingsMenu} ref={handleClick}>
                  {
                    checkPermission(['user:read']) &&
                    <>
                      <div onClick={() => { setUserGroupMenu(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/users`); }}>{t("user_management")}</div>
                      <div className={styles.line}></div>
                    </>
                  }
                  {
                    checkPermission(['role:read']) &&
                    <>
                      <div onClick={() => { setUserGroupMenu(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/roles`); }}>{t("role_management")}</div>
                      <div className={styles.line}></div>
                    </>
                  }
                  <div onClick={() => { setUserGroupMenu(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/users/invitations`); }}>{t("invitations_sent")}</div>
                </div>
              </div>
            </ToolTip>
            <ToolTip position='top' content={t("feedback")}>
              <button className={styles.menuItems} onClick={() => { navigate(`/feedback`); setActivePageButton('feedback'); }}>
                <img className={styles.menuIcons} src={location.pathname.includes('feedback') ? svgFeedBackFilled : svgFeedBackOutlined} alt="" />
              </button>
            </ToolTip>
          </div>
        </div>
        <div className={styles.versionContent}></div>
      </div>
      <div className={styles.mobileContainer}>
        <div className={styles.hamContainer}>
          <HamburgerMenu setIsMenuOpen={props.setIsMenuOpen} isMenuOpen={props.isMenuOpen}>
            <div className={styles.menuBtnContainer}>
              <button className={styles.mobileMenuBtn}
                onClick={() => { setActivePageButton("dashboard"); props.setIsMenuOpen(false); setIsAccordianOpen(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/dashboard`) }}>
                <div>
                  <img className={styles.menuIcons} src={svgDashboard} alt="" />
                </div>
                <div className={styles.menuFont}>{t("nav_bar_dashboard")}</div>
              </button>
              <button className={styles.mobileMenuBtn}
                onClick={() => { setActivePageButton("forms"); props.setIsMenuOpen(false); setIsAccordianOpen(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/forms`) }}>
                <div>
                  <img className={styles.menuIcons} src={svgForms} alt="" />
                </div>
                <div className={styles.menuFont}>{t("nav_bar_forms")}</div>
              </button>
              <div>
                <Accordion
                  onAccordionClick={() => setIsAccordianOpen(!isAccordianOpen)}
                  openState={isAccordianOpen}
                  btn_children={
                    <div className={styles.mobileMenuBtn} style={{ padding: "0 3px" }}>
                      <div style={{ display: "flex" }}>
                        <img className={styles.menuIcons} src={svgSettingsV2} alt="" />
                      </div>
                      <div className={styles.menuFont}>{t("settings")}</div>
                    </div>
                  }
                  btn_style={{
                    background: "none",
                    border: "none",
                    boxShadow: "none",
                    padding: "6px"
                  }}
                >
                  <div className={styles.mobileSubMenu}>
                    <button className={styles.mobileMenuBtn}
                      onClick={() => { setActivePageButton("analytics"); props.setIsMenuOpen(false); setIsAccordianOpen(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/settings`); }}>
                      <div>
                        &#10132;
                      </div>
                      <div className={styles.menuFont}>{t("workspace_settings")}</div>
                    </button>
                    <button className={styles.mobileMenuBtn}
                      onClick={() => { setActivePageButton("analytics"); props.setIsMenuOpen(false); setIsAccordianOpen(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/storage-manager`); }}>
                      <div>
                        &#10132;
                      </div>
                      <div className={styles.menuFont}>{t("storage_management")}</div>
                    </button>
                    <button className={styles.mobileMenuBtn}
                      onClick={() => { setActivePageButton("integrations"); props.setIsMenuOpen(false); setIsAccordianOpen(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/integrations`); }}>
                      <div>
                        &#10132;
                      </div>
                      <div className={styles.menuFont}>{t("integrations")}</div>
                    </button>
                  </div>
                </Accordion>
              </div>
              <div>
                <Accordion
                  onAccordionClick={() => setUserAccordian(!userAccordian)}
                  openState={userAccordian}
                  btn_children={
                    <div className={styles.mobileMenuBtn} style={{ padding: "0 3px" }}>
                      <div style={{ display: "flex" }}>
                        <img className={styles.menuIcons} src={svgUserGroupMobile} alt="" />
                      </div>
                      <div className={styles.menuFont}>{t("nav_bar_users")}</div>
                    </div>
                  }
                  btn_style={{
                    background: "none",
                    border: "none",
                    boxShadow: "none",
                    padding: "6px"
                  }}
                >
                  <div className={styles.mobileSubMenu}>
                    <button className={styles.mobileMenuBtn}
                      onClick={() => { setActivePageButton("analytics"); props.setIsMenuOpen(false); setUserAccordian(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/users`); }}>
                      <div>
                        &#10132;
                      </div>
                      <div className={styles.menuFont}>{t("user_management")}</div>
                    </button>
                    <button className={styles.mobileMenuBtn}
                      onClick={() => { setActivePageButton("analytics"); props.setIsMenuOpen(false); setUserAccordian(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/roles`); }}>
                      <div>
                        &#10132;
                      </div>
                      <div className={styles.menuFont}>{t("role_management")}</div>
                    </button>
                    <button className={styles.mobileMenuBtn}
                      onClick={() => { setActivePageButton("analytics"); props.setIsMenuOpen(false); setUserAccordian(false); if (checkWorkspace()) navigate(`/o/${activeOrgName}/users/invitations`); }}>
                      <div>
                        &#10132;
                      </div>
                      <div className={styles.menuFont}>{t("invitations_sent")}</div>
                    </button>
                  </div>
                </Accordion>
              </div>
              <button className={styles.mobileMenuBtn}
                onClick={() => { props.setIsMenuOpen(false); navigate('/feedback'); setIsAccordianOpen(false); }}>
                <div>
                  <img className={styles.feedBackIcon} src={svgFeedback} alt="feedback-button-active" />
                </div>
                <div className={styles.menuFont}>{t("feedback")}</div>
              </button>
              <div className={styles.logoutBtn}>
                <button className={styles.mobileMenuBtn}
                  onClick={() => { logoutAction(); }}>
                  <div >
                    <img className={styles.menuIcons} src={svgProfileIcon} alt="log-out" />
                  </div>
                  <div className={styles.menuFont}>{t("logout")}</div>
                </button>
              </div>
              <div className={styles.versionMobileContent}>{activeOrgName && activeOrgName.toUpperCase()}</div>
            </div>
          </HamburgerMenu>
          <div>
            <div className={`${styles.profileContainer} `}>
              <img
                onClick={() => { setIsProfileMenuActive(true) }}
                src={pngLogo} alt={"smart-forms"}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <ProfileMenu
              isMenuOpen={isProfileMenuActive}
              setIsMenuOpen={setIsProfileMenuActive}
            >
              <div className={styles.menuBtnContainer}>
                <div className={styles.mobileCloseBtn}>
                  <button className={styles.closebtn} onClick={() => { setIsProfileMenuActive(false) }}>
                    <img src={svgPaneDelete} alt="" />
                  </button>
                </div>
                <button className={styles.mobileMenuBtn}
                  onClick={() => { setIsProfileMenuActive(false); navigate("/profile") }} style={{ padding: "0 8px" }}>
                  <div style={{ display: "flex" }}>
                    <img className={styles.menuIcons} style={{ width: "1.4rem" }} src={svgProfile} alt="" />
                  </div>
                  <div className={styles.menuFont}>{t("my_profile")}</div>
                </button>
                <button className={styles.mobileMenuBtn}
                  onClick={() => { setIsProfileMenuActive(false); navigate("/profile/invites") }}>
                  <div style={{ display: "flex" }}>
                    <img className={styles.menuIcons} src={svgInvitation} alt="" />
                  </div>
                  <div className={styles.menuFont}>{t("my_invitation")}</div>
                </button>
                <button className={styles.mobileMenuBtn}
                  onClick={() => { setToggleAddOrg(true); setIsProfileMenuActive(false); }}>
                  <div>
                    <img className={styles.menuIcons} src={svgAddOrg} alt="" />
                  </div>
                  <div className={styles.menuFont}>{t("add_organization")}</div>
                </button>
                <button className={styles.mobileMenuBtn}
                  onClick={() => { setToggleSwitchOrg(true); setIsProfileMenuActive(false); }}>
                  <div>
                    <img className={styles.menuIcons} src={svgSwitchOrg} alt="" />
                  </div>
                  <div className={styles.menuFont}>{t("switch_workspace")}</div>
                </button>
              </div>
            </ProfileMenu>
          </div>
        </div>
      </div>
      <Modal showModal={toggleSwitchOrg} setShowModal={setToggleSwitchOrg}
        header={
          <div className={styles.endRaffleHeader}>
            <div className={styles.headerContainer}>
              <div style={{ fontWeight: '600' }}>
                {t("switch_workspace_text")}
              </div>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <CustomButton
                  style={{
                    background: "var(--accent-color)",
                    padding: "0.2rem",
                    borderRadius: '50%',
                    border: 'none',
                    boxSizing: "border-box",
                    display: 'flex'
                  }}
                  buttonType='button'
                  classType='tertiary'
                  onClick={() => { setToggleSwitchOrg(false); }}>
                  <img style={{ width: '1.2rem' }} src={svgPaneDelete} alt="" />
                </CustomButton>
              </div>
            </div>
          </div>
        }
        content={
          <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem', marginBottom: '2rem' }}>
              <div>{t("switch_workspace_warning")}</div>
              <CustomSelect
                data={
                  profile.organizations.map(item => {
                    const data: DropdownDataItem = {
                      label: `${item.displayName} (${item.name})`,
                      value: item.name
                    }
                    return data;
                  })}
                selectStyleType={"colored"}
                selectedData={(item) => setActiveOrg(item)}
                value={activeOrg}
              />
            </div>
            <div style={{ width: '5rem', marginLeft: 'auto' }}>
              <CustomButton buttonType={'button'} classType={'quaternary'} disabled={!isModified} onClick={() => { switchOrg() }}>{t("apply")}</CustomButton>
            </div>
          </>
        }
      />
      <AddOrganizationModal toggleAddOrg={toggleAddOrg} setToggleAddOrg={setToggleAddOrg} />
    </>
  )
}

