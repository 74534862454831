import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFoldersAndFormsById, fetchRootElements, fetchSharedElements } from "../../api/folders";
import { updateFormThunk } from "./forms.thunk";
import { MoveItemFormDto } from "../models/form.interface";
import { MoveItemFolderDto } from "../models/folders.interface";
import { FileType } from "../models/folder-type.enum";
import { updateFolderThunk } from "./folders.thunk";

export const fetchRootElementsThunk = createAsyncThunk(
  "folders/fetchRootElements",
  async () => {
    const resp = await fetchRootElements();
    return resp.data;
  }
)

export const fetchSharedElementsThunk = createAsyncThunk(
  "folders/fetchSharedElements",
  async () => {
    const resp = await fetchSharedElements();
    return resp.data;
  }
)

export const fetchFoldersAndFormsByIdThunk = createAsyncThunk(
  "folders/fetchFoldersAndFormsById",
  async (id: string) => {
    let resp = await fetchFoldersAndFormsById(id);
    return resp.data;
  }
);

export const moveItemThunk = createAsyncThunk(
  "folders/moveElements",
  async (params: MoveItemFolderDto | MoveItemFormDto, { dispatch }) => {
    if (params.type === FileType.FORM) {
      await dispatch(updateFormThunk({ formId: params.id, data: params.data })).unwrap();
    } else {
      await dispatch(updateFolderThunk({ id: params.id, data: params.data })).unwrap();
    }
    return params;
  }
);
