import { useTranslation } from 'react-i18next';
import CustomText from '../../common/custom-bodyText/custom-text';
import CustomHeading from '../../common/custom-heading/custom-heading';
import styles from './subs-base-plans.module.scss';
import { BaseSubscriptionPlan } from '../../../../store/models/subscription/plans';
import { Dispatch, SetStateAction } from 'react';
import { SubscriptionPlanCode } from '../../../../store/models/subscription/plan-name.enum';

export interface BasePlanProps {
  plans: BaseSubscriptionPlan[];
  selectedPlan?: BaseSubscriptionPlan;
  setSelectedPlan: Dispatch<SetStateAction<BaseSubscriptionPlan | undefined>>;
  isError: boolean;
  isFreeze: boolean;
}

export function BasePlans(props: BasePlanProps) {
  const { t } = useTranslation();
  const basePlans = [SubscriptionPlanCode.RESPONSE, SubscriptionPlanCode.SMS, SubscriptionPlanCode.STORAGE, SubscriptionPlanCode.USER];

  const renderPlans = (plan: BaseSubscriptionPlan, basePlan: SubscriptionPlanCode) => {
    if (plan.children?.find(val => val.code.toUpperCase() === basePlan.toUpperCase())) {
      return (
        <div>
          {
            plan.children?.find(val => val.code.toUpperCase() === basePlan.toUpperCase())?.planMetaData.map((meta, index) => {
              return (
                <div key={plan.id + `c${index}`}>{t("upto")}: {meta.up_to}, {t("amount")}: {meta.unit_amount}</div>
              )
            })
          }
        </div>
      )
    }
    return (
      <div>plan.planMetaData.responseCount</div>
    )
  }

  return (
    <div className={`${styles.container}  ${props.isError ? styles.containerErr : ''} ${props.isFreeze ? styles.containerDisabled : ''}`}>
      <div className={styles.titleContainer}>
        <CustomHeading headerType='h3'>{t("base_plan_main")}</CustomHeading>
        <CustomText textType='t5'>{t("base_plan_sub")}</CustomText>
      </div>
      <div className={styles.flexContainer}>
        {
          props.plans.map((plan, index) => {
            return (
              <div className={`${styles.planItem} ${props.selectedPlan?.id === plan.id ? styles.activePlanItem : ''}`} key={index} onClick={() => {
                props.setSelectedPlan(plan);
              }}>
                <div style={{ textAlign: 'center' }}>
                  <CustomHeading headerType='h3'>{plan.displayName}</CustomHeading>
                </div>
                <div className={styles.splitter}></div>
                <div style={{ margin: '1rem 0', textAlign: 'center' }}>
                  <CustomHeading headerType='h5'>${plan.planMetaData.baseAmount / 100}</CustomHeading>
                </div>
                <div className={'tableWrapper'}>
                  <>
                    <div className={'tableHeaderRow'}>
                      <div className={'tableData'}>{t("items")}</div>
                      <div className={'tableData'}>{t("usage_in_cents")}</div>
                    </div>
                    {
                      basePlans.map((basePlan, index) => {
                        return (
                          <div className={'tableRow'} key={index}>
                            <div className={`tableData ${styles.capitalize}`}>{basePlan.toLocaleLowerCase()}</div>
                            <div className={'tableData'} style={{ whiteSpace: 'normal' }}>
                              {
                                renderPlans(plan, basePlan)
                              }
                            </div>
                          </div>
                        );
                      })
                    }
                  </>
                </div>
                <div className='mobileContainer'>
                  {
                    basePlans.map((basePlan, index) => {
                      return (
                        <div className='mobileCard' key={index}>
                          <div className={`${styles.alignCentre} ${styles.boldFont} ${styles.capitalize}`}>{basePlan.toLocaleLowerCase()}</div>
                          <div className={styles.alignCentre}>
                            {
                              renderPlans(plan, basePlan)
                            }
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
      {
        props.isError &&
        <div className={styles.error}>{t("base_plan_sub")}</div>
      }
    </div>
  );
}
