import { PieTooltipProps, ResponsivePie } from '@nivo/pie'
import { fontFamily } from './constants';
import { TooltipWrapper } from '@nivo/tooltip';
import { colorGenerator } from './colorGenerator';
import { interpolateGnBu } from 'd3-scale-chromatic';
import styles from './charts.module.scss'

export default function PieChart(props: PieDataProps) {

  return (
    <ResponsivePie
      data={props.data.filter((item) => {
        if (item.value > 0) {
          return item;
        }
      })}
      margin={{ top: 20, right: 20, bottom: 80, left: 20 }}
      theme={{
        text: {
          fontFamily: fontFamily,
          fontSize: 12,
          color: "#ffff",
        },
        tooltip: {
          basic: {
            fontFamily: fontFamily,
            fontSize: 12,
          }
        },
      }}
      borderColor={"#000"}
      borderWidth={0.6}
      colors={colorGenerator(props.data.length, interpolateGnBu)}
      startAngle={0}
      innerRadius={0.5}
      padAngle={1}
      cornerRadius={0}
      enableArcLinkLabels={false}
      activeOuterRadiusOffset={8}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLabelsRadiusOffset={0.45}
      tooltip={(data) => {
        return <>{chartToolTip({ data: data })}</>
      }}
    // Temp fix
    // legends={[
    //     {
    //         anchor: props.analytics ? 'top-left' : 'bottom',
    //         direction: props.analytics ? 'column' : 'row',
    //         justify: false,
    //         translateX: 0,
    //         translateY: 56,
    //         itemsSpacing: props.analytics ? 7 : 0,
    //         itemWidth: 100,
    //         itemHeight: 18,
    //         itemTextColor: '#999',
    //         itemDirection: 'left-to-right',
    //         itemOpacity: isMobile ? 0 : 1,
    //         symbolSize: 18,
    //         symbolShape: 'circle',
    //         effects: [
    //             {
    //                 on: 'hover',
    //                 style: {
    //                     itemTextColor: '#000'
    //                 }
    //             }
    //         ],
    //         data: props.data.filter(val => {return val.value > 0}).map((cur, index) => ({
    //             id: cur.id,
    //             label: cur.label.length > 5 ? `${cur.label.substring(0, 5)}...` : cur.label,
    //             color: pieColors[index  < pieColors.length ? index : pieColors.length - index - 1]
    //         })),
    //     }
    // ]}
    // renderWrapper={false}
    />
  )
}

export function chartToolTip(props: { data: PieTooltipProps<RawDatum> }) {
  return (
    <TooltipWrapper anchor='bottom' position={[0, 0]}>
      <div className={styles.tooltipContainer}>
        <span style={{
          minWidth: '0.75rem',
          minHeight: '0.75rem',
          background: props.data.datum.color
        }}></span>
        <span>
          {props.data.datum.data.label} : <strong>{props.data.datum.data.value}</strong>
        </span>
      </div>
    </TooltipWrapper>
  )
}


export interface PieDataProps {
  analytics?: boolean;
  data: RawDatum[];
}

export interface RawDatum {
  id: string;
  label: string;
  value: number;
}
