import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../models/load-state.enum";
import { fetchFoldersAndFormsByIdThunk, fetchRootElementsThunk, fetchSharedElementsThunk } from "../thunks/files-order.thunk";
import { FileType } from "../models/folder-type.enum";
import { GetFolderItemRespDto } from "../models/folders.interface";
import { createFolderThunk, deleteFolderThunk } from "../thunks/folders.thunk";
import { createFormThunk, deleteFormThunk } from "../thunks/forms.thunk";
import { deleteItemUtil } from "../../util/delete-item.util";

export interface FileOrder {
  type: FileType;
  id: string;
  children: FileOrder[];
}

const filesOrderInitialState: {
  order: FileOrder[];
  sharedList: FileOrder[];
  fetchLoadingState: LoadState;
  createLoadingState: LoadState;
} = {
  order: [], sharedList: [], fetchLoadingState: LoadState.IDLE, createLoadingState: LoadState.IDLE
};

function traverseArr(data: FileOrder[], payload: GetFolderItemRespDto) {
  for (const file of data) {
    if (file.id === payload.id && payload.children) {
      const tempArr: FileOrder[] = [];
      for (const m of payload.children.files) {
        tempArr.push({
          type: m.type,
          id: m.data.id,
          children: []
        });
      }
      file.children = tempArr;
      return data;
    }
    if (file.children) {
      traverseArr(file.children, payload);
    }
  }
}

export const filesOrderSlice = createSlice({
  name: "filesOrder",
  initialState: filesOrderInitialState,
  reducers: {},
  extraReducers: (files) => {
    files
      .addCase(fetchRootElementsThunk.fulfilled, (state, action) => {
        const tempArr: FileOrder[] = [];
        for (const m of action.payload.files) {
          tempArr.push({
            type: m.type,
            id: m.data.id,
            children: []
          });
        }
        state.order = tempArr;
      })
      .addCase(fetchSharedElementsThunk.fulfilled, (state, action) => {
        const tempArr: FileOrder[] = [];
        for (const m of action.payload.files) {
          tempArr.push({
            type: m.type,
            id: m.data.id,
            children: []
          })
        }
        state.sharedList = tempArr;
      })
      .addCase(fetchFoldersAndFormsByIdThunk.fulfilled, (state, action) => {
        if (action.payload.children) {
          const data = action.payload.isShared ? [...state.sharedList] : [...state.order];
          const newData = traverseArr(data, action.payload);
          if (newData) {
            if (action.payload.isShared) {
              state.sharedList = [...newData];
            } else {
              state.order = [...newData];
            }
          }
        }
      })
      .addCase(createFolderThunk.fulfilled, (state, action) => {
        if (action.payload.parentID === null) {
          const data: FileOrder = {
            id: action.payload.id,
            type: FileType.FOLDER,
            children: []
          };
          state.order.unshift(data);
        }
      })
      .addCase(createFormThunk.fulfilled, (state, action) => {
        if (action.payload.folderID === null) {
          const data: FileOrder = {
            id: action.payload.id,
            type: FileType.FORM,
            children: []
          };
          state.order.unshift(data);
        }
      })
      .addCase(deleteFolderThunk.fulfilled, (state, action) => {
        deleteItemUtil(state.order, action.payload);
      })
      .addCase(deleteFormThunk.fulfilled, (state, action) => {
        deleteItemUtil(state.order, action.payload);
      });
  }
});

export default filesOrderSlice;
