import React, { useEffect, useState } from 'react';
import styles from './templates.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Template } from '../../../../store/models/template.interface';
import store, { RootState, RootDispatch } from '../../../../store/store';
import { getControlByFormThunk } from '../../../../store/thunks/controls.thunk';
import CustomText from '../../../../components/v2/common/custom-bodyText/custom-text';
import CustomButton from '../../../../components/v2/common/custom-button/custom-button';
import { CustomSearch } from '../../../../components/v2/common/custom-search/custom-search';
import { getTemplateTags } from '../../../../api/templates';
import { fetchAllTemplatesThunk } from '../../../../store/thunks/templates.thunk';
import { updateIndex } from '../../../../store/slices/step-index.slice';
import hasSufficientScopes from '../../../../util/access-validator/access-validator';
import toast from 'react-hot-toast';
import svgEmployee from "../../../../assets/REPLACE-THESE/employee.svg"; // TODO: Replace this asset. This embeds an image inside an SVG tag and is just a fake SVG. Who even does this?
import svgFilter from "../../../../assets/v2/icons/filter.svg";
import svgPaneDelete from "../../../../assets/v2/icons/pane-delete.svg";
import TextComponent from '../../../../components/v2/common/text-component/text-component';
import SecondaryTopBar from '../../../../components/v2/common/secondary-top-bar/secondary-top-bar';

export default function Templates() {

  let templates: Template[] = useSelector(
    (state: RootState) => state.templates.data
  );
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const [tags, setTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<boolean[]>([]);
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [isFilterMenuActive, setIsFilterMenuActive] = useState(false);
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTags = async () => {
      const res = await getTemplateTags();
      setTags(res);
      setSelectedTags(new Array(res.length).fill(false));

    };
    fetchTags();
  }, []);

  useEffect(() => {
    const tempTags: string[] = [];
    for (let i = 0; i < selectedTags.length; i++) {
      if (selectedTags[i] === true) {
        tempTags.push(tags[i]);
      }
    }
    let queryFilter = tempTags.join(",");
    setTag(queryFilter);
  }, [selectedTags]);


  useEffect(() => {
    dispatch(fetchAllTemplatesThunk({}));
  }, []);

  useEffect(() => {
    dispatch(fetchAllTemplatesThunk({ tags: tag, name: name }));
  }, [tag, name])

  const cloneForm = async (id: string, tags: string[]) => {
    if (isLatestSubscriptionActive) {
      const checkScope = hasSufficientScopes(["forms:create"]);
      if (checkScope) {
        dispatch(getControlByFormThunk(id));
        store.dispatch(updateIndex(1));
        navigate(`../forms/create?type=template&id=${id}&tags=${tags}`)
      } else {
        toast.error(t('form_creation_no_permission'));
      }
    } else {
      toast.error(t('no_active_subscription_error'));
    }
  };

  const handleSelect = (index: number) => {
    const tagsArray: boolean[] = [...selectedTags];
    tagsArray[index] = true;
    setSelectedTags(tagsArray);
  }

  const handleUnselect = (index: number) => {
    const newState: boolean[] = [...selectedTags];
    newState[index] = false;
    setSelectedTags(newState);
  }


  const filterDiv = () => (
    <>
      <div style={{ alignSelf: "baseline", width: "100%" }}>
        <div className={styles.tagTitleContainer}>
          <CustomText textType='t4'>
            <div className={styles.tagTitle}>{t('category_tags_templates')}</div>
          </CustomText>
          <div className={styles.mobileCloseBtn}>
            <button className={styles.closebtn} onClick={() => { setIsFilterMenuActive(false) }}>
              <img src={svgPaneDelete} alt="" />
            </button>
          </div>
        </div>
        <hr className={styles.line} />
      </div>
      <div className={styles.searchContainer}>
        <CustomSearch
          placeHolder='Search'
          searchWord={(item) => { setName(item) }}
          styles={{ paddingRight: "1.5rem" }}
        />
      </div>
      <div>
        {tags.map((item, index) => (
          <React.Fragment key={`${item}${index}`}>
            <div className={styles.filterHolder}>
              <input className={selectedTags[index] === true ? styles.selectedTag : styles.tag} type='button' value={item.length <= 25 ? item : item.slice(0, 25) + "..."} onClick={() => selectedTags[index] === false ? handleSelect(index) : handleUnselect(index)} />
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className={styles.applyBtn}>
        <CustomButton
          buttonType='button'
          classType='tertiary'
          onClick={() => { setIsFilterMenuActive(false) }}
          style={{
            fontSize: "12px",
            padding: "0.5rem 1rem"
          }}>
          {t('apply')}
        </CustomButton>
      </div>
    </>
  )

  return (
    <div style={{ background: 'var(--background-color)' }}>
      {/* <SideBar /> */}
      <SecondaryTopBar>
        <TextComponent textType='Content'>
          <div>{t('templates_default_kick_start')}</div>
        </TextComponent>
      </SecondaryTopBar>
      <div className={styles.mainContainer}>
        <div className={styles.mobileCloseBtn}>
          <CustomButton
            style={{
              background: "#ffff",
              padding: "0.625rem",
              boxSizing: "border-box",
              width: "12%"
            }}
            buttonType='button'
            classType='secondary'
            onClick={() => {
              setIsFilterMenuActive(true)
            }}
          >
            <div className={styles.isImageHolder}>
              <img style={{ width: "1rem" }} src={svgFilter} alt="filter" />
            </div>
          </CustomButton>
        </div>
        <div className={styles.templatesContainer}>
          <div className={styles.filterContainer}>
            {filterDiv()}
          </div>
          <div className={styles.templatesMain}>
            {
              templates.length > 0 ?
                <div className={styles.templates}>
                  {
                    templates.map((item) => (
                      <div className={styles.card} key={item.id} onClick={() => { cloneForm(item.id, item.tags) }}>
                        <div className={styles.cardImage}>
                          <img src={svgEmployee} alt="Job" />
                        </div>
                        <TextComponent textType='Content'>
                          <div className={styles.mobileTemplateTitle}>{item.name}</div>
                        </TextComponent>
                      </div>
                    ))
                  }
                </div>
                :
                <div className={styles.placeholderTemplate}>{`${t('templates_default_no_template')}`}</div>
            }
          </div>
        </div>
      </div>
      <div className={`${styles.actionMenuBg} ${isFilterMenuActive ? styles.actionMenuBgActive : ""}`}>
        <div className={`${styles.mobileFilterContainer} ${isFilterMenuActive ? styles.mobileFilterContainerActive : ""}`}>
          {filterDiv()}
        </div>
      </div>
    </div>
  )
}
