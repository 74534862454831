import { createAsyncThunk } from "@reduxjs/toolkit";
import { createForm, getFormById, updateForm, deleteForm, publishForm, submitFormForApproval } from "../../api/forms";
import { CreateFormReqDto, UpdateFormDto } from "../models/form.interface";

export const createFormThunk = createAsyncThunk(
  "forms/createForm",
  async (form: CreateFormReqDto) => {
    let response = await createForm(form);
    return response;
  }
)

export const fetchFormByIdThunk = createAsyncThunk(
  "forms/getFormById",
  async (id: string) => {
    return getFormById(id);
  }
)

export const updateFormThunk = createAsyncThunk(
  'forms/updateForm',
  async (params: { formId: string, data: UpdateFormDto }) => {
    await updateForm(
      params.formId,
      params.data
    );
    return params;
  })

export const deleteFormThunk = createAsyncThunk(
  "forms/deleteForm",
  async (params: { id: string, indexArr: number[] }) => {
    await deleteForm(params.id);
    return {
      id: params.id,
      indexArr: params.indexArr
    };
  }
);

export const publishFormThunk = createAsyncThunk("forms/publishForm", async (params: { id: string }) => {
  await publishForm(params.id);
  return params;
})

export const submitFormForApprovalThunk = createAsyncThunk(
  'forms/submitFormForApproval',
  async (params: { id: string }) => {
    await submitFormForApproval(params.id);
    return params;
  }
)
