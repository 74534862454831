export const RolePermissions = [
  'forms:create',
  'forms:delete',
  'forms:publish',
  'forms:read',
  'forms:update',
  'integrations:create',
  'integrations:delete',
  'integrations:read',
  'integrations:update',
  'payments:read',
  'response:delete',
  'response:read',
  'role:create',
  'role:delete',
  'role:read',
  'role:update',
  'subscription:cancel',
  'subscription:create',
  'subscription:read',
  'subscription:update',
  'user:create',
  'user:delete',
  'user:read',
  'user:update',
  'storage:delete',
  'storage:read',
  'apikeys:create',
  'apikeys:read',
  'apikeys:delete',
  'organization:delete'
];

export function getGroupedPermissions() {
  const grouped = new Map<string, string[]>();
  for (const p of RolePermissions) {
    const split = p.split(':');
    const resource = split[0];

    if (split.length === 1) {
      grouped.set(resource, []);
      continue;
    }
    if (grouped.has(resource)) {
      grouped.get(resource)?.push(split[1]);
    } else {
      grouped.set(resource, [split[1]]);
    }
  }
  return grouped;
}
