import { useState, useEffect, useContext } from 'react';
import styles from './forms.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from '../../../context/socket';
import { RootDispatch, RootState } from '../../../store/store';
import CustomHeading from '../../../components/v2/common/custom-heading/custom-heading';
import CustomButton from '../../../components/v2/common/custom-button/custom-button';
import { useTranslation } from 'react-i18next';
import CustomLoader from '../../../components/v2/common/custom-loader/custom-loader';
import { ACTIVE_PAGE_STATUS } from '../../../store/models/active-page';
import { activePageThunk } from '../../../store/thunks/active-page.thunk';
import { setNewData } from '../../../store/slices/active-page.slice';
import TextComponent from '../../../components/v2/common/text-component/text-component';
import CreateForm from './create-form/create-form';
import SecondaryTopBar from '../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import { UserMetadataKey } from '../../../store/models/user-metadata-key.enum';
import { fetchRootElementsThunk, fetchSharedElementsThunk } from '../../../store/thunks/files-order.thunk';
import { FilesList } from './files-list/files-list';
import addFolder from '../../../assets/add-folder.svg';
import ToolTip from '../../../components/v2/common/tool-tip/tool-tip';
import { FileActionEnum, FilesActions } from './file-action/file-action';
import { FileOrder } from '../../../store/slices/files-order.slice';
import { getOrgUsersThunk } from '../../../store/thunks/organization.thunk';
import CustomTabs from '../../../components/v2/common/custom-tabs/custom-tabs';

export function FormsV2() {
  const activePage = useSelector((state: RootState) => state.activePage.activePage);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const userWorkspace = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);
  const dispatch: RootDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [showFileActionsModal, setShowFileActionsModal] = useState(false);
  const [fileAction, setFileAction] = useState<FileActionEnum>();
  const [selectedFile, setSelectedFile] = useState<FileOrder>();
  const [indexArr, setIndexArr] = useState<number[]>([]);
  const [resetFileState, setResetFileState] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    dispatch(getOrgUsersThunk());
    setIsLoading(false);
  }, [userWorkspace]);

  useEffect(() => {
    if (tabIndex === 0) {
      dispatch(fetchRootElementsThunk());
    } else if (tabIndex === 1) {
      dispatch(fetchSharedElementsThunk());
    }
  }, [tabIndex]);

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activePage])

  useEffect(() => {
    if (newData) {
      dispatch(setNewData({ action: "" }));
    }
  }, [newData]);

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    dispatch(activePageThunk({ socket: socket, payload: { pageName: "forms", status } }));
  }

  return (
    <>
      <CreateForm
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <SecondaryTopBar>
        <div className={styles.titleContainer}>
          <TextComponent textType='Content'>
            <div>{t("dashboard_subheading")}</div>
          </TextComponent>
        </div>
        <div className={styles.createForm}>
          <CustomButton
            style={{
              padding: "5px 9px",
              letterSpacing: '0.5px',
              pointerEvents: 'all',
              boxShadow: 'none',
              border: 'none'
            }}
            buttonType='button'
            classType='quaternary'
            onClick={() => setShowModal(true)}
          >
            <div>
              <TextComponent textType='Content'>
                <div style={{ color: '#fff' }}>{`${t("create_form")} `}</div>
              </TextComponent>
            </div>
          </CustomButton>
          <div className={styles.addFolderWrapper}>
            <ToolTip position='bottom' content='Add Folder'>
              <CustomButton style={{ display: 'flex' }} buttonType={'button'} classType={'quaternary'} onClick={() => { setShowFileActionsModal(true); setFileAction(FileActionEnum.CREATE); }} >
                <img src={addFolder} alt='add-folder' className={styles.folderImg} />
              </CustomButton>
            </ToolTip>
          </div>
        </div>
      </SecondaryTopBar>
      <div className={styles.topContainer}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div className={styles.tabContainer}>
            <CustomTabs
              tabHeadings={[t("created_by_me"), t("shared_with_me")]}
              setActiveIndex={setTabIndex}
              btnStyles={{ padding: '0.25rem 0.75rem', whiteSpace: 'nowrap' }}
            />
          </div>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.containerTwo}>
          <div className={styles.mobileTitleContainer}>
            <CustomHeading headerType='h3' style={{ pointerEvents: 'none', userSelect: 'none' }}>
              <div>{t("your_forms")}</div>
            </CustomHeading>
          </div>
          <div className={styles.mobileTitleContainer}>
            <CustomButton style={{ display: 'flex', width: 'fit-content', marginLeft: 'auto' }} buttonType={'button'} classType={'quaternary'} onClick={() => { setShowFileActionsModal(true); setFileAction(FileActionEnum.CREATE); }} >
              <img src={addFolder} alt='add-folder' className={styles.folderImg} />
            </CustomButton>
          </div>
          <div style={{ position: "relative", width: "100%", flexGrow: "1", display: "flex" }}>
            {
              <div className={`${styles.loaderCube} ${isLoading ? '' : styles.loaderClose}`}><CustomLoader /></div>
            }
            <div className={`${styles.contentPane} ${isLoading ? '' : styles.contentReady}`}>
              {
                <div className={styles.fileListContainer}>
                  <FilesList tabIndex={tabIndex} setFileAction={setFileAction} setShowFileActionsModal={setShowFileActionsModal} selectedFile={selectedFile} onSelect={(file, indexArr) => { setSelectedFile(file); setIndexArr(indexArr); }} showMenu={tabIndex !== 1} resetFileState={resetFileState} showFiles={true} showFolders={true} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <FilesActions showModal={showFileActionsModal} setShowModal={setShowFileActionsModal} action={fileAction} setFileAction={setFileAction} selectedFile={selectedFile} setSelectedFile={setSelectedFile} indexArr={indexArr} setIndexArr={setIndexArr} setResetFileState={setResetFileState} />
    </>
  )
}
