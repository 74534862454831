import { useTranslation } from "react-i18next";
import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import CustomInput from "../../../../components/v2/common/custom-input/custom-input";
import { FileActionsProps } from "./file-action";
import styles from './file-action.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch, RootState } from "../../../../store/store";
import { useEffect, useRef, useState } from "react";
import TextComponent from "../../../../components/v2/common/text-component/text-component";
import { updateFolderThunk } from "../../../../store/thunks/folders.thunk";
import { UpdateFolderReqDto } from "../../../../store/models/folders.interface";
import toast from "react-hot-toast";

export function RenameFolder(props: Readonly<FileActionsProps>) {
  const { t } = useTranslation();
  const folderLookup = useSelector((state: RootState) => state.folders.data);
  const [folderName, setFolderName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [folderNameWarningText, setFolderNameWarningText] = useState<string | undefined>(undefined);
  const dispatch: RootDispatch = useDispatch();

  useEffect(() => {
    const listenerFunc = () => inputRef.current?.select();
    inputRef.current?.addEventListener('focus', listenerFunc);
    setTimeout(() => inputRef.current?.focus(), 10);

    return (() => {
      inputRef.current?.removeEventListener('focus', listenerFunc);
    })
  }, []);

  useEffect(() => {
    if (folderName.length < 1) {
      setFolderNameWarningText(t("folder_name_required"));
    } else {
      setFolderNameWarningText(undefined);
    }
  }, [folderName]);

  useEffect(() => {
    if (props.selectedFile) {
      setFolderName(folderLookup[props.selectedFile.id].displayName);
    } else setFolderName('');
  }, [props.selectedFile]);

  const renameFolder = () => {
    if (props.selectedFile?.id) {
      const payload: UpdateFolderReqDto = {
        displayName: folderName
      };
      try {
        dispatch(updateFolderThunk({ id: props.selectedFile.id, data: payload }));
        props.setShowModal(false);
        setFolderName('')
        toast.success(t("folder_rename_success"));
      } catch (err) {
        toast.error(t("folder_rename_error"));
        console.log("Error", err);
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <CustomInput inputStyleType={"primary"} inputType={'text'} value={folderName} ref={inputRef}
          onChange={(e) => {
            setFolderName(e.target.value);
          }}
        />
        {
          folderNameWarningText &&
          <TextComponent textType='Error'>
            <p >{folderNameWarningText}</p>
          </TextComponent>
        }
      </div>
      <div className={`${styles.panel} ${styles.btnContainer}`} >
        <CustomButton buttonType="button" classType="secondary" onClick={() => { setFolderName('') }}>{t("clear")}</CustomButton>
        <CustomButton
          buttonType="button"
          classType="quaternary"
          disabled={!!(folderNameWarningText && !props.selectedFile?.id)}
          onClick={() => { renameFolder() }}>{t("rename")}</CustomButton>
      </div>
    </div>
  )
}
