import { FileOrder } from "../store/slices/files-order.slice";

export function deleteItemUtil(order: FileOrder[], payload: { id: string, indexArr: number[] }) {
  if (payload.indexArr.length === 1) {
    order.splice(payload.indexArr[0], 1);
  } else {
    let element: FileOrder = order[payload.indexArr[0]];

    for (let i = 1; i < payload.indexArr.length; i++) {
      if (i !== payload.indexArr.length - 1) {
        element = element.children[payload.indexArr[i]];
      } else {
        element.children.splice(payload.indexArr[i], 1);
      }
    }
  }
}
