import { FileType } from "../../../../store/models/folder-type.enum";
import { GetFolderItemRespDto } from "../../../../store/models/folders.interface";
import { Form } from "../../../../store/models/form.interface";
import { FileOrder } from "../../../../store/slices/files-order.slice";

export function getPathFromRootForCurrentItem(item: FileOrder, folders: Record<string, GetFolderItemRespDto>, forms?: Record<string, Form>): string[] {
  const pathFragments = [];
  let temp = '';
  let parentFolderId = '';
  if (item.type === FileType.FOLDER) {
    temp = folders[item.id].displayName;
    parentFolderId = folders[item.id].parentID;
  } else if (forms && (item.type === FileType.FORM)) {
    temp = forms[item.id].name;
    parentFolderId = forms[item.id].folderID;
  }
  pathFragments.push(temp);

  while (parentFolderId !== null) {
    pathFragments.unshift(folders[parentFolderId].displayName);
    parentFolderId = folders[parentFolderId].parentID;
  }
  return pathFragments;
}
