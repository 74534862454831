import { useTranslation } from "react-i18next";
import { FileActionsProps } from "./file-action";
import styles from './file-action.module.scss';
import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import TextComponent from "../../../../components/v2/common/text-component/text-component";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import CustomSelect from "../../../../components/v2/common/custom-select/custom-select";
import { useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { DropdownDataItem } from "../../../../components/v2/common/custom-select/custom-select.props";
import svgPaneDelete from "../../../../assets/v2/close.svg";
import { listUsersOfResource, revokeAccess, shareItem } from "../../../../api/share-item";
import { CreateShareItemReqDto, GetUserListItemResponseDto, RevokeAccessReqDto } from "../../../../store/models/share-item.interface";
import { OPENFGA_ROLES, OPENFGA_TYPES } from "../../../../constants/openfga";
import toast from "react-hot-toast";
import { FileType } from "../../../../store/models/folder-type.enum";

export function ShareFile(props: Readonly<FileActionsProps>) {
  const { t } = useTranslation();
  const userId = useSelector((state: RootState) => state.user.profileInfo.userId);
  const users = useSelector((state: RootState) => state.organization.orgUsers).filter(f => f.id !== userId);
  const [selectedUser, setSelectedUser] = useState<MultiValue<DropdownDataItem>>([]);
  const permissionsData: DropdownDataItem[] = [{ label: 'Editor', value: OPENFGA_ROLES.EDITOR }, { label: 'Viewer', value: OPENFGA_ROLES.VIEWER }];
  const [selectedRole, setSelectedRole] = useState<SingleValue<DropdownDataItem>>();
  const [usersWithPermissionsStaticData, setUsersWithPermissionsStaticData] = useState<GetUserListItemResponseDto[]>();

  useEffect(() => {
    listUsers();
  }, []);

  const listUsers = async () => {
    if (props.selectedFile) {
      const users = await listUsersOfResource(props.selectedFile.type === FileType.FORM ? OPENFGA_TYPES.APP : OPENFGA_TYPES.FOLDER, props.selectedFile.id);
      setUsersWithPermissionsStaticData(users);
    }
  }

  const shareFile = async () => {
    try {
      if (props.selectedFile && selectedRole) {
        const data: CreateShareItemReqDto = {
          shareWithUserIds: selectedUser.map(m => { return m.value }),
          objectType: props.selectedFile.type === FileType.FORM ? OPENFGA_TYPES.APP : OPENFGA_TYPES.FOLDER,
          targetObjectId: props.selectedFile.id,
          relation: selectedRole.value
        }
        await shareItem(data);
        props.setShowModal(false);
        toast.success(t("file_share_success"));
      }
    } catch (err) {
      console.log("err", err);
      toast.error(t("file_share_failure"));
    }
  }

  const revokeFileAccess = async (userId: string, role: string) => {
    try {
      if (props.selectedFile) {
        const data: RevokeAccessReqDto = {
          revokeAccessUserId: userId,
          objectType: props.selectedFile.type === FileType.FORM ? OPENFGA_TYPES.APP : OPENFGA_TYPES.FOLDER,
          targetObjectId: props.selectedFile.id,
          relation: role
        };
        await revokeAccess(data);
        await listUsers();
        toast.success(t("revoke_access_success"));
      }
    } catch (err) {
      console.log("err", err);
      toast.error(t("revoke_access_failure"));
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <TextComponent textType={'Content'}>{t("individual_access")}</TextComponent>
        <div className={styles.shareInputContainer}>
          <div className={styles.userInput}>
            <CustomSelect
              data={users.map(u => { return { label: `${u.firstName} ${u.lastName} [${u.email}]`, value: u.id } })}
              selectStyleType="fade"
              selectedData={(item) => setSelectedUser(item as MultiValue<DropdownDataItem>)}
              value={selectedUser}
              isClearable
              isMultiSelect
            />
          </div>
          <div className={styles.roleInput}>
            <CustomSelect
              data={permissionsData}
              selectStyleType="fade"
              selectedData={(item) => setSelectedRole(item as SingleValue<DropdownDataItem>)}
              value={selectedRole}
              placeholder="Select"
            />
          </div>
        </div>
      </div>
      <div className={styles.panel}>
        <TextComponent textType={'Content'}>{t("manage_collaborators")}</TextComponent>
        <div className={styles.collabBox}>
          {
            usersWithPermissionsStaticData?.map((u, index) => {
              return (
                <div key={u.userId} className={styles.manageCollabContainer}>
                  <div className={styles.collabText}>
                    <TextComponent textType={'Content'}>{u.email}</TextComponent>
                  </div>
                  <div className={styles.collabInput}>
                    {<TextComponent textType={'Content'}>{u.role}</TextComponent>}
                  </div>
                  <div className={`${styles.roleEdit} ${u.userId === userId ? styles.disabled : ''}`}>
                    <CustomButton
                      style={{
                        background: "transparent",
                        padding: "0.2rem",
                        borderRadius: '50%',
                        border: 'none',
                        boxSizing: "border-box",
                        display: 'flex'
                      }}
                      buttonType='button'
                      classType='tertiary'
                      onClick={() => { revokeFileAccess(u.userId, u.role) }}>
                      <img style={{ width: '1.2rem' }} src={svgPaneDelete} alt="" />
                    </CustomButton>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className={`${styles.panel} ${styles.btnContainer}`} >
        <CustomButton buttonType="button" classType="secondary" onClick={() => { props.setShowModal(false); props.setFileAction(undefined); props.setSelectedFile(undefined); setSelectedUser([]); setSelectedRole(undefined); }}>{t("cancel")}</CustomButton>
        <CustomButton
          buttonType="button"
          classType="quaternary"
          disabled={!selectedRole || selectedUser.length === 0}
          onClick={shareFile}>
          {t("share")}
        </CustomButton>
      </div>
    </div>
  )
}
