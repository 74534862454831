import { useEffect, useState } from 'react'
import styles from './form-details.module.scss';
import FormDetailsHeader from './form-details-header/form-details-header';
import { Outlet, useParams } from 'react-router-dom';
import { RootDispatch, RootState } from '../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '../../../store/models/form.interface';
import { getForm } from "../../../store/slices/forms.slice";
import PublishHelper from './publish-helper/publish-helper';
import { ActionsPanel } from '../../../components/v2/form-builder/actions-panel/actions-panel';
import { useTranslation } from 'react-i18next';
import CustomLoader from '../../../components/v2/common/custom-loader/custom-loader';
import { UserMetadataKey } from '../../../store/models/user-metadata-key.enum';
import PageNotFound from '../../page-not-found/page-not-found';
import { fetchFormByIdThunk } from '../../../store/thunks/forms.thunk';

export default function FormDetails() {

  const { formId } = useParams();
  const form: Form | undefined = useSelector((state: RootState) => getForm(state, formId as string));
  const [isShareMenuActive, setIsShareMenuActive] = useState(false);
  const [isShareVisible, setShareVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const [refreshPage, setRefreshPage] = useState<boolean>(true);
  const dispatch: RootDispatch = useDispatch();
  const userWorkspace = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);

  useEffect(() => {
    const temp = async () => {
      try {
        formId && await dispatch(fetchFormByIdThunk(formId)).unwrap();
        setRefreshPage(false);
      } catch (error) {
        setRefreshPage(false);
      }
    }
    temp();
  }, [userWorkspace])


  return (
    <>
      {
        refreshPage ?
          <div style={{
            position: 'absolute',
            top: 'calc(100vh / 2.5)',
            left: 'calc(100vw / 2.3)',
          }}>
            <CustomLoader />
          </div> :
          <>
            {
              form ?
                <>
                  <FormDetailsHeader
                    isShareMenuActive={isShareMenuActive}
                    setIsShareMenuActive={setIsShareMenuActive}
                    isShareVisible={isShareVisible}
                    setShareVisible={setShareVisible}
                  />
                  <div className={styles.mainContainer}>
                    <div className={styles.outletContainer}>
                      <Outlet />
                    </div>
                  </div>
                  <div className={styles.mobileActionPanel}>
                    <ActionsPanel title={t("share_form")} isActionsPanelOpen={isShareVisible} setIsActionsPanelOpen={setShareVisible}>
                      <PublishHelper setIsShareMenuActive={setIsShareMenuActive} url={form && form.url}></PublishHelper>
                    </ActionsPanel>
                  </div>
                  <div className={`${styles.actionMenuBg} ${isShareMenuActive ? styles.actionMenuBgActive : ""}`}>
                    <div className={`${styles.mobileFilterContainer} ${isShareMenuActive ? styles.mobileFilterContainerActive : ""}`}>
                      <PublishHelper setIsShareMenuActive={setIsShareMenuActive} url={form && form.url}></PublishHelper>
                    </div>
                  </div>
                </> : <PageNotFound />
            }
          </>
      }
    </>
  )
}
