import { useSelector } from "react-redux"
import { RootState } from "../../../../store/store"
import { FileItem } from "./file-item/file-item";
import { FileOrder } from "../../../../store/slices/files-order.slice";
import { FileActionEnum } from "../file-action/file-action";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { FileType } from "../../../../store/models/folder-type.enum";
import TextComponent from "../../../../components/v2/common/text-component/text-component";
import svgNoForms from "../../../../assets/v2/icons/no-forms.svg";
import styles from "../forms.module.scss";

export interface FilesListProps {
  actionMode?: boolean;
  onSelect?: (file: FileOrder, indexArr: number[]) => void;
  selectedFile?: FileOrder;
  setFileAction?: Dispatch<SetStateAction<FileActionEnum | undefined>>;
  setShowFileActionsModal?: Dispatch<SetStateAction<boolean>>;
  showMenu: boolean;
  showFiles: boolean;
  showFolders: boolean;
  resetFileState?: boolean;
  tabIndex?: number;
  currentFile?: FileOrder;
}

export function FilesList(props: Readonly<FilesListProps>) {
  const fileOrder = useSelector((state: RootState) => props.tabIndex === 1 ? state.filesOrder.sharedList : state.filesOrder.order);
  const formLookup = useSelector((state: RootState) => props.tabIndex === 1 ? state.forms.sharedData : state.forms.data);
  const folderLookup = useSelector((state: RootState) => props.tabIndex === 1 ? state.folders.sharedData : state.folders.data);
  const { t } = useTranslation();
  const user: any = useSelector((state: RootState) => state.user);

  return (
    <>
      {
        fileOrder.length > 0
          ?
          <>
            {
              fileOrder.map((file, index) => {
                if ((((file.type === FileType.FOLDER) && props.showFolders) || ((file.type === FileType.FORM) && props.showFiles))) {
                  return (
                    <FileItem file={file} folderLookup={folderLookup} formLookup={formLookup} key={file.id} depthCount={0} actionMode={props.actionMode} currentFile={props.currentFile} selectedFile={props.selectedFile} onSelect={props.onSelect} setFileAction={props.setFileAction} setShowFileActionsModal={props.setShowFileActionsModal} indexArr={[index]} showMenu={props.showMenu} showChildrenFiles={props.showFiles} showChildrenFolders={props.showFolders} resetFileState={props.resetFileState} />
                  )
                }
                return <></>;
              })
            }
          </>
          :
          <div className={styles.noFormsContainer} style={{ pointerEvents: 'none', userSelect: 'none' }}>
            <div>
              <img src={svgNoForms} alt="no-forms" />
            </div>
            <TextComponent textType='Content'>
              <div>{props.tabIndex === 1 ? t("no_shared_forms_message", { name: user.data.name }) : t("dashboard_create_forms", { name: user.data.name })}</div>
            </TextComponent>
          </div>
      }
    </>
  )
}
