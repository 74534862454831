import { createSlice } from "@reduxjs/toolkit";
import { Form, FormState } from "../models/form.interface";
import { LoadState } from "../models/load-state.enum";
import { RootState } from "../store";
import { createFormThunk, deleteFormThunk, fetchFormByIdThunk, publishFormThunk, submitFormForApprovalThunk, updateFormThunk } from "../thunks/forms.thunk";
import { fetchFoldersAndFormsByIdThunk, fetchRootElementsThunk, fetchSharedElementsThunk } from "../thunks/files-order.thunk";
import { FileType } from "../models/folder-type.enum";

const formsInitialState: {
  data: Record<string, Form>;
  sharedData: Record<string, Form>;
  count: number;
  current: number;
  skip: number;
  fetchLoadingState: LoadState;
  createLoadingState: LoadState;
  createControlLoadingState: LoadState;
} = {
  data: {}, sharedData: {}, count: 0, current: 0, skip: 0, fetchLoadingState: LoadState.IDLE, createLoadingState: LoadState.IDLE, createControlLoadingState: LoadState.IDLE
};

export const formsSlice = createSlice({
  name: "forms",
  initialState: formsInitialState,
  reducers: {
    setActiveUsers(state, action) {
      if (state.data[action.payload.formId]) {
        state.data[action.payload.form] = action.payload.activeUsers;
      }
    }
  }, extraReducers: (builder) => {
    builder
      .addCase(fetchRootElementsThunk.fulfilled, (state, action) => {
        for (const item of action.payload.files) {
          if (item.type === FileType.FORM) {
            const formData: Form = {
              id: item.data.id,
              name: item.data.name,
              description: item.data.description,
              state: item.data.state,
              url: item.data.url,
              tags: item.data.tags,
              expiryTime: item.data.expiryTime,
              expiryResponseCount: item.data.expiryResponseCount,
              isPublic: item.data.isPublic,
              deletedAt: item.data.deletedAt,
              publishedAt: item.data.publishedAt,
              createdAt: item.data.createdAt,
              updatedAt: item.data.updatedAt,
              config: item.data.config,
              createdBy: item.data.createdBy,
              updatedBy: item.data.updatedBy,
              timerConfig: item.data.timerConfig,
              createdByUserName: item.data.createdByUserName,
              activeUsers: item.data.activeUsers,
              integrationIds: item.data.integrationIds,
              folderID: item.data.folderID
            }
            state.data = {
              ...state.data,
              [item.data.id]: formData
            }
          }
        }
      })
      .addCase(fetchSharedElementsThunk.fulfilled, (state, action) => {
        for (const item of action.payload.files) {
          if (item.type === FileType.FORM) {
            state.sharedData = {
              ...state.sharedData,
              [item.data.id]: item.data as Form
            }
          }
        }
      })
      .addCase(fetchFoldersAndFormsByIdThunk.fulfilled, (state, action) => {
        if (action.payload.children) {
          for (const item of action.payload.children.files) {
            if (item.type === FileType.FORM) {
              if (action.payload.isShared) {
                state.sharedData = {
                  ...state.sharedData,
                  [item.data.id]: item.data
                }
              } else {
                state.data = {
                  ...state.data,
                  [item.data.id]: item.data
                }
              }
            }
          }
        }
      })
      .addCase(createFormThunk.pending, (state) => {
        state.createLoadingState = LoadState.LOADING;
      })
      .addCase(createFormThunk.fulfilled, (state, action) => {
        let data = action.payload;
        let form: Form = {
          name: data.name,
          tags: data.tags,
          id: data.id,
          description: data.description,
          isPublic: data.isPublic,
          state: data.state,
          expiryResponseCount: data.expiryResponseCount,
          expiryTime: data.expiryTime,
          timerConfig: data.timerConfig,
          url: data.url,
          config: data.config,
          createdBy: data.createdBy,
          createdAt: data.createdAt,
          createdByUserName: data.createdByUserName,
          folderID: data.folderID
        };
        state.data = {
          ...state.data,
          [form.id]: form
        };
      })
      .addCase(fetchFormByIdThunk.fulfilled, (state, action) => {
        const data = action.payload;
        let form: Form = {
          tags: data.tags,
          name: data.name,
          id: data.id,
          description: data.description,
          isPublic: data.isPublic,
          url: data.url,
          state: data.state,
          expiryResponseCount: data.expiryResponseCount,
          expiryTime: data.expiryTime,
          config: data.config,
          createdBy: data.createdBy,
          createdAt: data.createdAt,
          timerConfig: data.timerConfig,
          createdByUserName: data.createdByUserName,
          activeUsers: data.activeUsers,
          folderID: data.folderID
        };
        state.data[form.id] = form;
      })
      .addCase(updateFormThunk.fulfilled, (state, action) => {
        const form = { ...state.data[action.payload.formId] };
        const data = action.payload.data;

        Object.keys(data).forEach(key => {
          const keyObject = key as keyof object;
          form[keyObject] = data[keyObject];
        });

        state.data = {
          ...state.data,
          [form.id]: form
        };
      })
      .addCase(deleteFormThunk.fulfilled, (state, action) => {
        delete state.data[action.payload.id];
      })
      .addCase(publishFormThunk.fulfilled, (state, action) => {
        const form = { ...state.data[action.payload.id] };
        form.state = FormState.PUBLISHED;

        state.data = {
          ...state.data,
          [action.payload.id]: form
        };
      })
      .addCase(submitFormForApprovalThunk.fulfilled, (state, action) => {
        const form = { ...state.data[action.payload.id] };
        form.state = FormState.WAITING_FOR_PUBLISH;

        state.data = {
          ...state.data,
          [action.payload.id]: form
        };
      })
  },
});

export const getForm = (state: RootState, formId: string) => {
  return state.forms.data[formId];
};

export const getAllForms = (state: RootState) => {
  return state.forms.data;
}

export const getTotalFormsCount = (state: RootState) => state.forms.count;

export const getCurrentFormsCount = (state: RootState) => state.forms.current;

export const { setActiveUsers } = formsSlice.actions;

export default formsSlice;
