import { useTranslation } from "react-i18next";
import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import { RootDispatch } from "../../../../store/store";
import { FileActionsProps } from "./file-action";
import styles from './file-action.module.scss';
import { FileType } from "../../../../store/models/folder-type.enum";
import TextComponent from "../../../../components/v2/common/text-component/text-component";
import { useDispatch } from "react-redux";
import { deleteFolderThunk } from "../../../../store/thunks/folders.thunk";
import toast from "react-hot-toast";
import { fetchRootElementsThunk } from "../../../../store/thunks/files-order.thunk";
import { deleteFormThunk } from "../../../../store/thunks/forms.thunk";

export function DeleteFile(props: Readonly<FileActionsProps>) {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();

  const deleteFileAction = () => {
    try {
      if (props.selectedFile) {
        if (props.selectedFile.type === FileType.FOLDER) {
          dispatch(deleteFolderThunk({ id: props.selectedFile?.id, indexArr: props.indexArr }));
        } else {
          dispatch(deleteFormThunk({ id: props.selectedFile?.id, indexArr: props.indexArr }))
        }
        props.setShowModal(false);
        props.setFileAction(undefined);
        props.setSelectedFile(undefined);
        if (props.setIndexArr) props.setIndexArr([]);
        toast.success(t("delete_item_success"));
        dispatch(fetchRootElementsThunk());
        props.setResetFileState((prev) => !prev);
      }
    } catch (err) {
      console.log("err", err);
      toast.error(t("delete_item_error"));
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <TextComponent textType={'Content'}>{t("delete_confirmation")} {props.selectedFile?.type === FileType.FOLDER ? `${t("folder")}?` : `${t("form")}?`}</TextComponent>
      </div>
      <div className={`${styles.panel} ${styles.btnContainer}`} >
        <CustomButton buttonType="button" classType="secondary" onClick={deleteFileAction}>{t("yes")}</CustomButton>
        <CustomButton buttonType="button" classType="quaternary" onClick={() => { props.setShowModal(false); props.setFileAction(undefined); props.setSelectedFile(undefined); }}>{t("no")}</CustomButton>
      </div>
    </div>
  )
}
