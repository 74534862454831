import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './dashboard.module.scss';
import CustomButton from '../../../components/v2/common/custom-button/custom-button';
import { fetchAllFormsCount, getDashboardAnalytics } from '../../../api/forms';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../store/store';
import { PieDataProps } from '../../../components/common/charts/pie';
import { Charts } from '../../../store/models/charts.enum';
import { BarChartData } from '../../../components/common/charts/bar';
import { fetchFormListThunk } from '../../../store/thunks/form-responses.thunk';
import { fetchSubscriptionThunk } from '../../../store/thunks/subscription.thunk';
import CustomLoader from '../../../components/v2/common/custom-loader/custom-loader';
import DashboardChartStatistics from './dashboard-chart-statistics';
import DashboardCards from './dashboard-cards';
import { activePageThunk } from '../../../store/thunks/active-page.thunk';
import { SocketContext } from '../../../context/socket';
import { ACTIVE_PAGE_STATUS } from '../../../store/models/active-page';
import { setNewData } from '../../../store/slices/active-page.slice';
import svgNoFormUser from "../../../assets/v2/icons/noform-user.svg";
import TextComponent from '../../../components/v2/common/text-component/text-component';
import CreateForm from '../forms/create-form/create-form';
import SecondaryTopBar from '../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import { UserMetadataKey } from '../../../store/models/user-metadata-key.enum';
import svgPaneDelete from "../../../assets/v2/close.svg";
import { useNavigate } from 'react-router-dom';
import { fetchRootElementsThunk } from '../../../store/thunks/files-order.thunk';

export default function DashboardV2() {
  const { t } = useTranslation();
  const dispatch: RootDispatch = useDispatch();
  const user: any = useSelector((state: RootState) => state.user);
  const count: number = useSelector((state: RootState) => state.formResponses.totalCount);
  const latestSubscription = useSelector((state: RootState) => state.subscription.data);
  const [publishedFormsCount, setPublishedFormsCount] = useState(0);
  const [nonPublishedFormsCount, setNonPublishedFormsCount] = useState(0);
  const [waitingForPublishFormsCount, setWaitingForPublishFormsCount] = useState(0);
  const [pieChartData, setPieChartData] = useState<PieDataProps>();
  const [avgResponseRate, setAvgResponseRate] = useState(0);
  const socket = useContext(SocketContext);
  const activeWebPage = useSelector((state: RootState) => state.activePage.activePage);
  const newData = useSelector((state: RootState) => state.activePage.newData);
  const [showModal, setShowModal] = useState(false);
  const [formConversionData, setFormConversionData] = useState<BarChartData>({
    data: [],
    keys: [],
    groupMode: 'stacked',
    layout: "horizontal",
    indexBy: ""
  });
  const [deviceInfoData, setDeviceInfoData] = useState<PieDataProps>({ data: [] });
  const [engagingFormsData, setEngagingFormsData] = useState<BarChartData>({
    data: [],
    keys: [],
    groupMode: 'stacked',
    layout: "horizontal",
    indexBy: ""
  });
  const [formStatusData, setFormStatusData] = useState<PieDataProps>({ data: [] });
  const [isFreeSubBannerOpen, setIsFreeSubBannerOpen] = useState(true);
  const [totalFormsCount, setTotalFormsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const profile = useSelector((state: RootState) => state.user.profileInfo.metadata[UserMetadataKey.ACTIVE_ORGANIZATION]);
  const navigate = useNavigate();

  const getFormsCount = async () => {
    const res = await fetchAllFormsCount();
    if (res) {
      setTotalFormsCount(res.count);
      setPublishedFormsCount(res.published);
      setNonPublishedFormsCount(res.draft);
      setWaitingForPublishFormsCount(res.approvalPending);
    }
  }

  useEffect(() => {
    updateActivePage(ACTIVE_PAGE_STATUS.ACTIVE);
    return () => {
      // Unsubscribe when the component is unmounted
      updateActivePage(ACTIVE_PAGE_STATUS.INACTIVE);
    };
  }, [activeWebPage])

  useEffect(() => {
    if (newData) {
      onPageLoad();
      dispatch((setNewData({ action: "" })));
    }
  }, [newData])

  const updateActivePage = async (status: ACTIVE_PAGE_STATUS) => {
    dispatch(activePageThunk({ socket: socket, payload: { pageName: "dashboard", status } }));
  }

  const averageResponseRate = (data: PieDataProps) => {
    if (data.data.length > 1) {
      if (data.data[0].value === 0 && data.data[1].value > 0) {
        setAvgResponseRate(100);
      }
      else if (data.data[0].value > 0 && data.data[1].value === 0) {
        setAvgResponseRate(0);
      }
      else {
        if (((data.data[1].value / data.data[0].value) * 100) > 100) {
          setAvgResponseRate(100);
        } else {
          setAvgResponseRate(data.data[1].value / data.data[0].value * 100);
        }
      }
    }
  }

  useEffect(() => {
    onPageLoad();
  }, [profile]);

  const onPageLoad = () => {
    dispatch(fetchRootElementsThunk());
    dispatch(fetchSubscriptionThunk());
    getFormsCount();
    if (count === 0) {
      dispatch(fetchFormListThunk({}));
    }
    getStats();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  const getStats = async () => {
    const data = await Promise.all([getDashboardAnalytics(Charts.FORMCONVERSION), getDashboardAnalytics(Charts.DEVICEANALYTICS), getDashboardAnalytics(Charts.ENGAGINGFORMS), getDashboardAnalytics(Charts.FORMSTATUS)]);
    const conversionPie = data[0];
    setFormConversionData({
      data: conversionPie.data.data,
      groupMode: "grouped",
      indexBy: "id",
      keys: ["value"],
      layout: "vertical"
    });
    averageResponseRate(conversionPie.data);
    const deviceInfoPie = data[1];
    setDeviceInfoData({ data: deviceInfoPie.data.data });
    const engagingFormsBar = data[2];
    setEngagingFormsData({
      data: engagingFormsBar.data.data,
      groupMode: "stacked",
      indexBy: "id",
      keys: ["value"],
      layout: "horizontal"
    });
    const formStatusPie = data[3];
    setFormStatusData({ data: formStatusPie.data.data });
    setPieChartData({
      data: [
        {
          "id": "Draft",
          "label": "Draft",
          "value": nonPublishedFormsCount,
        },
        {
          "id": "Published",
          "label": "Published",
          "value": publishedFormsCount,
        }
      ]
    });
    setIsLoading(false);
  }

  return (
    <>
      <CreateForm
        setShowModal={setShowModal}
        showModal={showModal}
      />
      {
        !latestSubscription.isActive && isFreeSubBannerOpen
          ?
          <div className={styles.bannerMain}>
            <div className={styles.freeBanner} >
              <div className={styles.innerBanner} >
                <div className={styles.bannerTitle}>
                  <TextComponent textType='Content'>
                    {`${t("free_sub_banner")}`}
                  </TextComponent>
                </div>
                <div>
                  <CustomButton
                    buttonType='button'
                    classType='quaternary'
                    onClick={() => { navigate("../settings") }}>
                    <TextComponent textType='Content'>
                      <div style={{ color: '#fff' }}>{`${t("upgrade")}`}</div>
                    </TextComponent>
                  </CustomButton>
                </div>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <CustomButton
                  style={{
                    background: "var(--accent-color)",
                    padding: "0.2rem",
                    borderRadius: '50%',
                    border: 'none',
                    boxSizing: "border-box",
                    display: 'flex'
                  }}
                  buttonType='button'
                  classType='tertiary'
                  onClick={() => { setIsFreeSubBannerOpen(false); }}>
                  <img style={{ width: '1.2rem' }} src={svgPaneDelete} alt="" />
                </CustomButton>
              </div>
            </div>
          </div>
          : null
      }

      <div className={`${isLoading ? '' : styles.loaderClose}`}>
        <CustomLoader />
        {/* <div>{`${t("loader_dashboard")}`}</div> */}
      </div>
      <SecondaryTopBar>
        <div className={styles.welcomeContainer} style={{ pointerEvents: 'none', userSelect: 'none' }}>
          <TextComponent textType='Header'>
            <div>{`${t("hello_user")}`} <span style={{ textTransform: 'capitalize' }}>{user.data.name}</span>!</div>
          </TextComponent>
          <TextComponent textType='Content'>
            <div>{`${t("header_content_dashboard")}`}</div>
          </TextComponent>
        </div>
        <div className={`${styles.welcomeContainer} ${styles.btnCont}`} style={{ userSelect: 'none' }}>
          <CustomButton
            style={{
              padding: "5px 9px",
              letterSpacing: '0.5px',
              boxShadow: 'none',
              border: 'none'
            }}
            buttonType='button'
            classType='quaternary'
            onClick={() => setShowModal(true)}
          >
            <div>
              <TextComponent textType='Content'>
                <div style={{ color: '#fff' }}>{`${t("create_form")} `}</div>
              </TextComponent>
            </div>
          </CustomButton>
        </div>
      </SecondaryTopBar>
      <div className={styles.main}>
        <div className={`${styles.contentPane} ${isLoading ? '' : styles.contentReady}`}>
          <DashboardCards
            avgResponseRate={avgResponseRate}
            nonPublishedFormsCount={nonPublishedFormsCount}
            publishedFormsCount={publishedFormsCount}
            waitingForPublishFormsCount={waitingForPublishFormsCount}
            t={t}
          />
          {
            (totalFormsCount) > 0 ? (
              <DashboardChartStatistics
                deviceInfoData={deviceInfoData}
                engagingFormsData={engagingFormsData}
                formConversionData={formConversionData}
                formStatusData={formStatusData}
                t={t}
              />
            ) : (
              <div className={styles.noFormContainer}>
                <TextComponent textType='Header'>
                  <div style={{ pointerEvents: 'none', userSelect: 'none' }}>{`${t('no_forms_dashboard', { name: user.data.name })}`}</div>
                </TextComponent>
                <div style={{ pointerEvents: 'none', userSelect: 'none' }}>
                  <img src={svgNoFormUser} alt="no forms" />
                </div>
                <div style={{ userSelect: 'none' }}>
                  <CustomButton
                    style={{ padding: "9px 9px" }}
                    buttonType='button'
                    classType='quaternary'
                    onClick={() => setShowModal(true)}>
                    <TextComponent textType='Content'>
                      <div style={{ color: '#fff' }}>{`${t("create_form")} `}</div>
                    </TextComponent>
                  </CustomButton>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}
