import { Dispatch, SetStateAction } from "react";
import { CreateFolder } from "./create-folder";
import Modal from "../../../../components/v2/common/modal/modal";
import TextComponent from "../../../../components/v2/common/text-component/text-component";
import CustomButton from "../../../../components/v2/common/custom-button/custom-button";
import svgPaneDelete from "../../../../assets/v2/close.svg";
import styles from './file-action.module.scss';
import { DeleteFile } from "./delete-file";
import { MoveFolder } from "./move-folder";
import { RenameFolder } from "./rename-folder";
import { FileOrder } from "../../../../store/slices/files-order.slice";
import { FileType } from "../../../../store/models/folder-type.enum";
import { useTranslation } from "react-i18next";
import { ShareFile } from "./share-file";

export enum FileActionEnum {
  CREATE,
  MOVE,
  RENAME,
  DELETE,
  SHARE
}

export interface FileActionsProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  action?: FileActionEnum;
  setFileAction: Dispatch<SetStateAction<FileActionEnum | undefined>>;
  setSelectedFile: Dispatch<SetStateAction<FileOrder | undefined>>;
  selectedFile: FileOrder | undefined;
  indexArr: number[];
  setIndexArr: Dispatch<SetStateAction<number[]>>;
  setResetFileState: Dispatch<SetStateAction<boolean>>;
  resetFileState?: boolean;
}

export function FilesActions(props: Readonly<FileActionsProps>) {
  const { t } = useTranslation();

  return (<>
    {
      <Modal
        setShowModal={props.setShowModal}
        showModal={props.showModal}
        header={<div style={{ display: 'flex' }}>
          <TextComponent textType='Header'>
            {
              props.action === FileActionEnum.CREATE && <div>{t("create")} {t("folder")}</div>
            }
            {
              props.action === FileActionEnum.DELETE && <div>{t("delete")} {props.selectedFile?.type === FileType.FOLDER ? `${t("folder")}` : `${t("form")}`}</div>
            }
            {
              props.action === FileActionEnum.MOVE && <div>{t("move")} {props.selectedFile?.type === FileType.FOLDER ? `${t("folder")}` : `${t("form")}`}</div>
            }
            {
              props.action === FileActionEnum.RENAME && <div>{t("rename")} {t("folder")}</div>
            }
            {
              props.action === FileActionEnum.SHARE && <div>{t("share")} {props.selectedFile?.type === FileType.FOLDER ? `${t("folder")}` : `${t("form")}`}</div>
            }
          </TextComponent>
          <div className={styles.modalDeleteIconContainer}>
            <CustomButton
              style={{
                background: "var(--accent-color)",
                padding: "0.2rem",
                borderRadius: '50%',
                border: 'none',
                boxSizing: "border-box",
                display: 'flex'
              }}
              buttonType='button'
              classType='tertiary'
              onClick={() => { props.setShowModal(false); props.setFileAction(undefined); props.setSelectedFile(undefined); props.setIndexArr([]); }}>
              <img style={{ width: '1.2rem' }} src={svgPaneDelete} alt="" />
            </CustomButton>
          </div>
        </div>}
        content={
          <>
            {
              props.action === FileActionEnum.CREATE &&
              <CreateFolder {...props} />
            }
            {
              props.action === FileActionEnum.DELETE &&
              <DeleteFile {...props} />
            }
            {
              props.action === FileActionEnum.MOVE &&
              <MoveFolder {...props} />
            }
            {
              props.action === FileActionEnum.RENAME &&
              <RenameFolder {...props} />
            }
            {
              props.action === FileActionEnum.SHARE &&
              <ShareFile {...props} />
            }
          </>
        }
      />
    }
  </>)
}
