import axios from "axios";
import { CreateShareItemReqDto, GetUserListItemResponseDto, RevokeAccessReqDto } from "../store/models/share-item.interface";
import { GenerateApiUrl } from "./generate-api-url";

export async function shareItem(data: CreateShareItemReqDto) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'access/share', true);
  const res = await axios.post(apiUrl, data);
  return res.data;
}

export async function revokeAccess(data: RevokeAccessReqDto) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'access/revoke', true);
  const res = await axios.post(apiUrl, data);
  return res.data;
}

export async function listUsersOfResource(objectType: string, objectId: string): Promise<GetUserListItemResponseDto[]> {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `access/list-users?objectType=${objectType}&targetObjectId=${objectId}`, true);
  const res = await axios.get(apiUrl);
  return res.data.users;
}
