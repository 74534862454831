import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateFolderReqDto, UpdateFolderReqDto } from "../models/folders.interface";
import { createFolder, deleteFolder, updateFolder } from "../../api/folders";

export const createFolderThunk = createAsyncThunk(
  "folders/createFolder",
  async (folder: CreateFolderReqDto) => {
    let resp = await createFolder(folder);
    return resp;
  }
)

export const deleteFolderThunk = createAsyncThunk(
  "folders/deleteFolder",
  async (params: { id: string, indexArr: number[] }) => {
    await deleteFolder(params.id);
    return {
      id: params.id,
      indexArr: params.indexArr
    }
  }
);

export const updateFolderThunk = createAsyncThunk(
  "folders/updateFolder",
  async (params: { id: string, data: UpdateFolderReqDto }) => {
    await updateFolder(params.id, params.data);
    return params;
  }
);

