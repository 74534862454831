export const OPENFGA_ACTIONS = {
  CAN_VIEW: 'can_view',
  CAN_EDIT: 'can_edit',
  CAN_DELETE: 'can_delete',
  HAS_OWNERSHIP: 'has_ownership'
}

export const OPENFGA_ROLES = {
  ADMIN: 'admin',
  MEMBER: 'member',
  EDITOR: 'editor',
  VIEWER: 'viewer',
  PARENT: 'parent'
}

export const OPENFGA_TYPES = {
  APP: 'app',
  FOLDER: 'folder',
  WORKSPACE: 'workspace',
  RESPONSE: 'response',
  GROUP: 'group',
  USER: 'user'
}
