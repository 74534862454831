import axios, { AxiosResponse } from "axios";
import { CreateFolderReqDto, GetFilesRespDto, GetFolderItemRespDto, GetSharedFilesRespDto, UpdateFolderReqDto } from "../store/models/folders.interface";
import { GenerateApiUrl } from "./generate-api-url";
import toast from 'react-hot-toast';

export async function createFolder(folder: CreateFolderReqDto): Promise<GetFolderItemRespDto> {
  let apiUrl: string = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'folders', true);
  let response: AxiosResponse<GetFolderItemRespDto, any>;
  try {
    response = await axios.post(apiUrl, folder);
    return response.data;
  } catch (error) {
    toast.error(`An error occurred while creating the folder. Please try again later`);
    throw error;
  }
}

export async function fetchFoldersAndFormsById(id: string, filters?: { includeCreatedByMe?: string }) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `folders/${id}`, true);
  let params = {};
  if (filters?.includeCreatedByMe) {
    Object.assign(params, { includeCreatedByMe: filters.includeCreatedByMe });
  }
  const response = await axios.get<GetFolderItemRespDto>(apiUrl, { params: params });
  return response;
}

export async function updateFolder(id: string, data: UpdateFolderReqDto) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `folders/${id}`, true);
  try {
    await axios.patch<void>(apiUrl, data);
  } catch (error) {
    toast.error(`An error occurred while updating the folder. Please try again later`);
    throw error;
  }
}

export async function deleteFolder(id: string) {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, `folders/${id}`, true);
  await axios.delete<void>(apiUrl);
}

export async function fetchRootElements() {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'folders/root', true);
  const response = await axios.get<GetFilesRespDto>(apiUrl);
  return response;
}

export async function fetchSharedElements() {
  const apiUrl = GenerateApiUrl(process.env.REACT_APP_MANAGER_SVC as string, 'folders/shared-list', true);
  const response = await axios.get<GetSharedFilesRespDto>(apiUrl);
  return response;
}
