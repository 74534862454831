import { useTranslation } from 'react-i18next';
import SecondaryTopBar from '../../../../../components/v2/common/secondary-top-bar/secondary-top-bar';
import TextComponent from '../../../../../components/v2/common/text-component/text-component';
import SettingRefreshComponent from '../../setting-refresh-component';
import CustomButton from '../../../../../components/v2/common/custom-button/custom-button';
import { useEffect, useState } from 'react';
import styles from './api-keys-tab.module.scss';
import hasSufficientScopes from '../../../../../util/access-validator/access-validator';
import { useSelector, useDispatch } from 'react-redux';
import { RootDispatch, RootState } from '../../../../../store/store';
import CustomLoader from '../../../../../components/v2/common/custom-loader/custom-loader';
import NoContent from '../../../../../components/v2/common/no-content/no-content';
import ToolTip from '../../../../../components/v2/common/tool-tip/tool-tip';
import svgDelete from "../../../../../assets/v2/delete.svg";
import Modal from '../../../../../components/v2/common/modal/modal';
import toast from 'react-hot-toast';
import { ActionsPanel } from '../../../../../components/v2/form-builder';
import GenerateAPIKey from '../generate-api-key/generate-api-key';
import { deleteAPIKeyThunk, getAPIKeysThunk } from '../../../../../store/thunks/api-keys.thunk';

export default function APIKeysTab() {
  const dispatch: RootDispatch = useDispatch();
  const apiKeys = useSelector((state: RootState) => state.apiKeys.data);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [revokeAPIKeyId, setRevokeAPIKeyId] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [isGenerateActive, setIsGenerateActive] = useState(true);
  const isLatestSubscriptionActive = useSelector((state: RootState) => state.subscription.data.isActive);

  useEffect(() => {
    getLatestData();
  }, []);

  const getLatestData = async () => {
    setIsLoading(true);
    const checkScope = hasSufficientScopes(["apikeys:read"]);
    if (checkScope) {
      await dispatch(getAPIKeysThunk());
    }
    setIsLoading(false);
  }

  const revokeAPIKey = async (id: string) => {
    if (isLatestSubscriptionActive) {
      const checkScope = hasSufficientScopes(["apikeys:delete"]);
      if (checkScope) {
        try {
          await dispatch(deleteAPIKeyThunk(id)).unwrap();
          setRevokeAPIKeyId("");
          setShowModal(false);
          toast.success(t("api_key_deleted"));
        } catch (err) {
          toast.error(t("api_key_delete_faile"));
        }
      } else {
        toast.error(t("api_key_delete_no_permission"));
      }
    } else {
      toast.error(t('no_active_subscription_error'))
    }
  }

  return (
    <>
      <SecondaryTopBar>
        <div>
          <TextComponent textType='Content'>
            <div>{t("api_keys_management_subheading")}</div>
          </TextComponent>
        </div>
        <div className={styles.addBtnContainer}>
          <SettingRefreshComponent
            getLatestData={getLatestData}
            refreshPage={isLoading}
          />
          <CustomButton
            buttonType='button'
            classType='quaternary'
            style={{
              padding: "5px 2rem",
              letterSpacing: '0.5px',
              boxShadow: 'none',
              border: 'none'
            }}
            onClick={async () => {
              if (isLatestSubscriptionActive) {
                const checkScope = hasSufficientScopes(["apikeys:create"]);
                if (checkScope) {
                  setIsSidePaneOpen(true);
                  setIsGenerateActive(true);
                }
              }
            }}
          >
            <TextComponent textType='Content'>
              <div style={{ color: '#fff' }}>{t("generate_api_key")}</div>
            </TextComponent>
          </CustomButton>
        </div>
      </SecondaryTopBar>
      <div className={styles.actionPaneMobile}>
        {
          isSidePaneOpen &&
          <ActionsPanel title={"Generate API Key"} style={{ top: "5rem", minHeight: "calc(100vh - 6rem)", minWidth: "40rem", maxWidth: "40rem", transform: `${isGenerateActive ? "translate3d(0, 0, 0)" : "translate3d(40rem, 0, 0)"}` }} isActionsPanelOpen={isGenerateActive} setIsActionsPanelOpen={setIsGenerateActive}>
            {
              isGenerateActive && <GenerateAPIKey setIsSidePaneOpen={setIsSidePaneOpen}></GenerateAPIKey>
            }
          </ActionsPanel>
        }
      </div>
      <div className={`${styles.userMainContainer}`}>
        <div>
          <Modal showModal={showModal} setShowModal={setShowModal}
            header={
              <div>{t("sure_delete_api_key")}</div>
            }
            content={
              <div style={{ display: "flex", gap: "1rem" }}>
                <CustomButton
                  buttonType='button'
                  classType='secondary'
                  onClick={() => revokeAPIKey(revokeAPIKeyId)}>
                  <div style={{ color: "#000" }}>{t("yes")}</div>
                </CustomButton>
                <CustomButton
                  buttonType='button'
                  classType='quaternary'
                  onClick={() => { setShowModal(false) }}>
                  <div>{t("no")}</div>
                </CustomButton>
              </div>
            }
          />
          <div style={{ position: "relative" }}>
            {
              <div className={`${isLoading ? '' : styles.loaderClose}`}><CustomLoader /></div>
            }
            {
              <div className={`${styles.contentPane} ${isLoading ? '' : styles.contentReady}`}>

                {
                  apiKeys && apiKeys.length > 0 ? (
                    <div className={'tableWrapper'}>
                      <div className={'tableHeaderRow'}>
                        <div className={'tableData'}>{t("api_key_name")}</div>
                        <div className={'tableData'}>{t("api_key")}</div>
                        <div className={'tableData'}>{t("created_on")}</div>
                        <div className='tableData'>{t("actions")}</div>
                      </div>
                      {
                        apiKeys.map((item) => {
                          return (
                            <div key={`k${item.id}`} className={'tableRow'}>
                              <div className={'tableData'}>{item.name}</div>
                              <div className={'tableData'}>{item.prefix}.*************</div>
                              <div className={'tableData'}>{new Date(item.createdAt).toLocaleDateString()}</div>
                              <div className={`${'tableData'} ${styles.actionContainer}`}>
                                <ToolTip content={`${t("revoke_api_key")}`} position='bottom'>
                                  <CustomButton
                                    style={{
                                      background: "var(--accent-color)",
                                      padding: " 12px",
                                      borderRadius: '50%',
                                      border: 'none',
                                      boxSizing: "border-box"
                                    }}
                                    buttonType='button'
                                    classType='tertiary'
                                    /**
                                     * {isLatestSubscriptionActive ? (hasSufficientScopes(["apikeys:delete"]) ? false : true): true}  -- 1
                                     *
                                     * The above expression was there earlier inside 'disabled(just below this comment)' and since Sonar cube was commenting in PR saying
                                     * 'Severity: MAJOR', I will try to solve it the old school way.
                                     *
                                     * Let a = isLatestSubscriptionActive, b = hasSufficientScopes(["apikeys:delete"]) are two boolean variables and result = disabled.
                                     *
                                     * If we construct truth table for the above expression 1, we get.
                                     * a      b     result
                                     * 1      1       0
                                     * 1      0       1
                                     * 0      0       1
                                     * 0      1       1
                                     *
                                     * I was searching for Truth Table to Boolean expression online, but when I wrote it down in paper, I realised that this was
                                     * easier than I thought. It is non other than boolean table of NAND. i.e result = !(a & b). And hence I will replace the expression now.
                                     */
                                    disabled={!(isLatestSubscriptionActive && hasSufficientScopes(["apikeys:delete"]))}
                                    onClick={() => {
                                      setRevokeAPIKeyId(item.id);
                                      setShowModal(true);
                                    }}
                                  >
                                    <div className={styles.isImageHolder}>
                                      <img style={{ width: "1.2rem" }} src={svgDelete} alt="delete" />
                                    </div>
                                  </CustomButton>
                                </ToolTip>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  ) : (
                    <NoContent
                      content={`${t("no_api_keys_to_show")}`}
                    />
                  )
                }
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
