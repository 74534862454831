import { createSlice } from "@reduxjs/toolkit";
import { GetFolderItemRespDto } from "../models/folders.interface";
import { fetchFoldersAndFormsByIdThunk, fetchRootElementsThunk, fetchSharedElementsThunk } from "../thunks/files-order.thunk";
import { FileType } from "../models/folder-type.enum";
import { createFolderThunk, deleteFolderThunk, updateFolderThunk } from "../thunks/folders.thunk";

const foldersInitialState: {
  data: Record<string, GetFolderItemRespDto>;
  sharedData: Record<string, GetFolderItemRespDto>;
} = {
  data: {}, sharedData: {}
};

export const foldersSlice = createSlice({
  name: "folders",
  initialState: foldersInitialState,
  reducers: {
  }, extraReducers: (folders) => {
    folders
      .addCase(fetchRootElementsThunk.fulfilled, (state, action) => {
        for (const item of action.payload.files) {
          if (item.type === FileType.FOLDER) {
            state.data = {
              ...state.data,
              [item.data.id]: item.data
            }
          }
        }
      })
      .addCase(fetchSharedElementsThunk.fulfilled, (state, action) => {
        for (const item of action.payload.files) {
          if (item.type === FileType.FOLDER) {
            state.sharedData = {
              ...state.sharedData,
              [item.data.id]: item.data as GetFolderItemRespDto
            }
          }
        }
      })
      .addCase(fetchFoldersAndFormsByIdThunk.fulfilled, (state, action) => {
        if (action.payload.children) {
          for (const item of action.payload.children.files) {
            if (item.type === FileType.FOLDER) {
              if (action.payload.isShared) {
                state.sharedData = {
                  ...state.sharedData,
                  [item.data.id]: item.data
                }
              } else {
                state.data = {
                  ...state.data,
                  [item.data.id]: item.data
                }
              }
            }
          }
        }
      })
      .addCase(createFolderThunk.fulfilled, (state, action) => {
        const folder: GetFolderItemRespDto = action.payload;
        state.data = {
          ...state.data,
          [folder.id]: folder
        }
      })
      .addCase(deleteFolderThunk.fulfilled, (state, action) => {
        delete state.data[action.payload.id];
      })
      .addCase(updateFolderThunk.fulfilled, (state, action) => {
        const folder = { ...state.data[action.payload.id] };
        const data = action.payload.data;

        Object.keys(data).forEach(key => {
          const keyObject = key as keyof object;
          folder[keyObject] = data[keyObject];
        });

        state.data = {
          ...state.data,
          [folder.id]: folder
        };
      })
  }
});

export default foldersSlice;
