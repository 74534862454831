import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "../components/common/login/login";
import PageNotFound from '../pages/page-not-found/page-not-found';
import ForgotPassword from "../pages/v2/user-onboarding/forgot-password/forgot-password";
import LoginNew from "../pages/v2/user-onboarding/login-new";
import SignUp from "../pages/v2/user-onboarding/sign-up/sign-up";
import UserType from "../pages/v2/user-onboarding/user-type/user-type";
import ValidateOtp from "../pages/v2/user-onboarding/validate-otp/validate-otp";
import ResetPassword from "../pages/v2/user-onboarding/reset-password/reset-password";
import SubscriptionSuccessPage2 from "../pages/v2/subscription/success/success";
import SubscriptionFail2 from "../pages/v2/subscription/fail/fail";
import DashboardV2 from "../pages/v2/dashboard/dashboard";
import { FormsV2 } from "../pages/v2/forms/forms";
import Templates from "../pages/v2/forms/templates/templates";
import LayoutV2 from "../pages/v2/layout/layout";
import FormsSetupV2 from "../pages/v2/forms-setup/forms-setup-v2";
import { FormBuilder } from "../pages/v2/form-builder/form-builder";
import Forbidden from "../pages/forbidden-pages/forbidden";
import TechnicalGlitch from "../pages/technical-glitch/technical-glitch";
import { FeedbackPage } from "../pages/feedback-page/feedback-page";
import UsersTab from "../pages/v2/setting/user-management/users-tab/users-tab";
import OrgInvitations from "../pages/v2/setting/org-invitations/org-invitations";
import Profile from "../pages/v2/account/profile/profile";
import UserInvitations from "../pages/v2/account/user-invitations/user-invitations";
import OrgSettings from "../pages/v2/setting/profile/org-settings";
import RolesTab from "../pages/v2/setting/user-management/roles-tab/roles-tab";
import { Integrations } from "../pages/v2/setting/integrations/integrations";
import ResponseOverview from "../pages/v2/form-response/responses-overview/responses-overview";
import ResponseDetails from "../pages/v2/form-response/responses-details/response-details";
import ScoreCard from "../pages/v2/form-response/score-card/score-card";
import Analytics from "../pages/v2/form-response/analytics/analytics";
import FormDetails from "../pages/v2/form-details/form-details";
import WorkspaceValidator from "../components/common/workspace-validator/workspace-validator";
import { Plans } from "../pages/v2/plans/plans";
import FormDetailsContainer from "../pages/v2/form-details/form-details-container";
import CustomLink from "../pages/v2/form-details/custom-link/custom-link";
import RafflePage from "../pages/v2/form-details/raffle-card/raffle-card";
import QuizScore from "../pages/v2/form-details/quiz-score/quiz-score";
import Protected from "../util/protected/protected";
import StorageManager from "../pages/storage-manager/storage-manager";
import APIKeysTab from "../pages/v2/setting/api-keys-management/api-keys-tab/api-keys-tab";

function RouteComponent() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} >
          <Route index element={<LoginNew />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="validate-otp" element={<ValidateOtp />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="user-type" element={<UserType />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route element={<LayoutV2 />}>
            {/* Profile pages */}
            <Route path="profile" element={<Profile />} />
            <Route path="profile/invites" element={<UserInvitations />} />
            <Route path="feedback" element={<FeedbackPage />} />
            {/* Error Pages */}
            <Route path="404" element={<PageNotFound />} />
            <Route path="403" element={<Forbidden />} />
            <Route path="500" element={<TechnicalGlitch />} />
            <Route path={`o/:workspaceId`} element={<WorkspaceValidator />}>
              <Route path="dashboard" element={<Protected element={<DashboardV2 />} scopes={['response:read', 'forms:read']} />} />
              <Route path="templates" element={<Templates />} />
              <Route path="storage-manager" element={<Protected element={<StorageManager />} scopes={['storage:read']} />} />
              <Route path="subscription/success" element={<SubscriptionSuccessPage2 />} />
              <Route path="subscription/fail" element={<SubscriptionFail2 />} />
              <Route path="forms" element={<Protected element={<FormsV2 />} scopes={['forms:read']} />} />
              <Route path="forms/:formId" element={<Protected element={<FormDetails />} scopes={['forms:read']} />}>
                <Route path="" element={<FormDetailsContainer />} />
                <Route path="custom-link" element={<CustomLink />} />
                <Route path="raffle" element={<RafflePage />} />
                <Route path="quiz" element={<QuizScore />} />
                <Route path="responses" element={<Protected element={<ResponseOverview />} scopes={['response:read']} />} />
                <Route path="analytics" element={<Protected element={<Analytics />} scopes={['response:read']} />} />
                <Route path="responses/score-card" element={<Protected element={<ScoreCard />} scopes={['response:read']} />} />
                <Route path="responses/:responseId" element={<Protected element={<ResponseDetails />} scopes={['response:read']} />} />
              </Route>
              <Route path="forms/create" element={<Protected element={<FormsSetupV2 />} scopes={['forms:create']} />} />
              <Route path="forms/:formId/edit" element={<Protected element={<FormBuilder />} scopes={['forms:create', 'forms:update']} />} />
              <Route path="settings" element={<Protected element={<OrgSettings />} scopes={['subscription:read', 'payments:read', 'subscription:create']} />} />
              <Route path="subscription-plans" element={<Protected element={<Plans />} scopes={['subscription:read']} />} />
              <Route path="roles" element={<Protected element={<RolesTab />} scopes={['role:read']} />} />
              <Route path="api-keys" element={<Protected element={<APIKeysTab />} scopes={['apikeys:read']} />} />
              <Route path="integrations" element={<Protected element={<Integrations />} scopes={['integrations:read']} />} />
              <Route path="users" element={<Protected element={<UsersTab />} scopes={['user:read']} />} />
              <Route path="users/invitations" element={<Protected element={<OrgInvitations />} scopes={['user:read']} />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RouteComponent;
